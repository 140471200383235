import React, { useState, useEffect } from "react";
import { addAddress, getAddress, globalLoderAction } from "../Redux/Action";
import { useSelector, useDispatch } from "react-redux";
import GetAddress from "./GetAddress";
import "./AddressForm.css";
function AddressForm() {
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  const [pincodeError, setPincodeError] = useState("");
  const [address, setAddress] = useState({
    state: "",
    city: "",
    street: "",
    landmark: "",
    zipcode: "",
    primary: "",
    district: "",
    addressType: "",
  });
  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    const timer = setTimeout(() => {
      dispatch(globalLoderAction("hideLoader"));
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  const handleChange = (e) => {
    if (e.target.name === "zipcode") {
      if (!/^[0-9]{10}$/.test(e.target.value)) {
        setPincodeError("Please enter a valid Pincode");
      } else {
        setPincodeError("");
      }
    }
    setAddress({ ...address, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(addAddress(address))
      .then((res) => {
        setLoad(false);
        dispatch(getAddress());
      })
      .catch((err) => {
        //
      });
  };
  useEffect(() => {
    window.scrollTo(1000, 0);
  }, []);
  return (
    <div>
      <div class="user-area pt-100 pb-70">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-12">
              <div class="user-all-form">
                <div class="contact-form">
                  <h3 class="user-title">Add Address</h3>
                  <form onSubmit={handleSubmit}>
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <input
                            type="text"
                            name="street"
                            value={address.street}
                            onChange={handleChange}
                            class="form-control"
                            placeholder="Street"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <input
                            type="text"
                            name="city"
                            value={address.city}
                            onChange={handleChange}
                            class="form-control"
                            placeholder="City"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <select
                            class="form-select"
                            name="district"
                            value={address.district}
                            onChange={handleChange}
                            aria-label="Default select example"
                            style={{
                              maxWidth: "510px",
                              marginLeft: "0px",
                              height: "49px",
                            }}
                          >
                            <option selected> Select District </option>
                            <option value="Abhayapuri">Abhayapuri</option>
                            <option value="Achabbal">Achabbal</option>
                            <option value="Achhnera">Achhnera</option>
                            <option value="Adalaj">Adalaj</option>
                            <option value="Adari">Adari</option>
                            <option value="Adilabad">Adilabad</option>
                            <option value="Adityana">Adityana</option>
                            <option value="Adoni">Adoni</option>
                            <option value="Adoor">Adoor</option>
                            <option value="Adra">Adra</option>
                            <option value="Agartala">Agartala</option>
                            <option value="Agra">Agra</option>
                            <option value="Ahiwara">Ahiwara</option>
                            <option value="Ahmedabad">Ahmedabad</option>
                            <option value="Ahmedgarh">Ahmedgarh</option>
                            <option value="Ahmednagar">Ahmednagar</option>
                            <option value="Aizawl">Aizawl</option>
                            <option value="Ajmer">Ajmer</option>
                            <option value="Akaltara">Akaltara</option>
                            <option value="Akathiyoor">Akathiyoor</option>
                            <option value="Akhnoor">Akhnoor</option>
                            <option value="Akola">Akola</option>
                            <option value="Alang">Alang</option>
                            <option value="Alappuzha">Alappuzha</option>
                            <option value="Aldona">Aldona</option>
                            <option value="Aligarh">Aligarh</option>
                            <option value="Alipurduar">Alipurduar</option>
                            <option value="Allahabad">Allahabad</option>
                            <option value="Almora">Almora</option>
                            <option value="Along">Along</option>
                            <option value="Alwar">Alwar</option>
                            <option value="Amadalavalasa">Amadalavalasa</option>
                            <option value="Amalapuram">Amalapuram</option>
                            <option value="Amarpur">Amarpur</option>
                            <option value="Ambagarh chowki">
                              Ambagarh chowki
                            </option>
                            <option value="Ambaji">Ambaji</option>
                            <option value="Ambala">Ambala</option>
                            <option value="Ambala">Ambala</option>
                            <option value="Ambaliyasan">Ambaliyasan</option>
                            <option value="Ambikapur">Ambikapur</option>
                            <option value="Amguri">Amguri</option>
                            <option value="Amlabad">Amlabad</option>
                            <option value="Amli">Amli</option>
                            <option value="Amravati">Amravati</option>
                            <option value="Amreli">Amreli</option>
                            <option value="Amritsar">Amritsar</option>
                            <option value="Amroha">Amroha</option>
                            <option value="Anakapalle">Anakapalle</option>
                            <option value="Anand">Anand</option>
                            <option value="Anandapur">Anandapur</option>
                            <option value="Anandnagaar">Anandnagaar</option>
                            <option value="Anantapur">Anantapur</option>
                            <option value="Anantnag">Anantnag</option>
                            <option value="Ancharakandy">Ancharakandy</option>
                            <option value="Andada">Andada</option>
                            <option value="Anjar">Anjar</option>
                            <option value="Anklav">Anklav</option>
                            <option value="Ankleshwar">Ankleshwar</option>
                            <option value="Antaliya">Antaliya</option>
                            <option value="Anugul">Anugul</option>
                            <option value="Ara">Ara</option>
                            <option value="Arakkonam">Arakkonam</option>
                            <option value="Arambagh">Arambagh</option>
                            <option value="Arambhada">Arambhada</option>
                            <option value="Arang">Arang</option>
                            <option value="Araria">Araria</option>
                            <option value="Arasikere">Arasikere</option>
                            <option value="Arcot">Arcot</option>
                            <option value="Areraj">Areraj</option>
                            <option value="Arki">Arki</option>
                            <option value="Arnia">Arnia</option>
                            <option value="Aroor">Aroor</option>
                            <option value="Arrah">Arrah</option>
                            <option value="Aruppukkottai">Aruppukkottai</option>
                            <option value="Asankhurd">Asankhurd</option>
                            <option value="Asansol">Asansol</option>
                            <option value="Asarganj">Asarganj</option>
                            <option value="Ashok nagar">Ashok nagar</option>
                            <option value="Ashtamichira">Ashtamichira</option>
                            <option value="Asika">Asika</option>
                            <option value="Asola">Asola</option>
                            <option value="Assandh">Assandh</option>
                            <option value="Ateli">Ateli</option>
                            <option value="Attingal">Attingal</option>
                            <option value="Atul">Atul</option>
                            <option value="Aurangabad">Aurangabad</option>
                            <option value="Aurangabad">Aurangabad</option>
                            <option value="Avinissery">Avinissery</option>
                            <option value="Awantipora">Awantipora</option>
                            <option value="Azamgarh">Azamgarh</option>
                            <option value="Babiyal">Babiyal</option>
                            <option value="Baddi">Baddi</option>
                            <option value="Bade bacheli">Bade bacheli</option>
                            <option value="Badepalle">Badepalle</option>
                            <option value="Badharghat">Badharghat</option>
                            <option value="Bagaha">Bagaha</option>
                            <option value="Bahadurganj">Bahadurganj</option>
                            <option value="Bahadurgarh">Bahadurgarh</option>
                            <option value="Baharampur">Baharampur</option>
                            <option value="Bahraich">Bahraich</option>
                            <option value="Bairgania">Bairgania</option>
                            <option value="Bakhtiarpur">Bakhtiarpur</option>
                            <option value="Balaghat">Balaghat</option>
                            <option value="Balangir">Balangir</option>
                            <option value="Balasore">Balasore</option>
                            <option value="Baleshwar">Baleshwar</option>
                            <option value="Bali">Bali</option>
                            <option value="Ballabhgarh">Ballabhgarh</option>
                            <option value="Ballia">Ballia</option>
                            <option value="Bally">Bally</option>
                            <option value="Balod">Balod</option>
                            <option value="Baloda bazar">Baloda bazar</option>
                            <option value="Balrampur">Balrampur</option>
                            <option value="Balurghat">Balurghat</option>
                            <option value="Bamra">Bamra</option>
                            <option value="Banda">Banda</option>
                            <option value="Bandikui">Bandikui</option>
                            <option value="Bandipore">Bandipore</option>
                            <option value="Bangalore">Bangalore</option>
                            <option value="Banganapalle">Banganapalle</option>
                            <option value="Banka">Banka</option>
                            <option value="Bankura">Bankura</option>
                            <option value="Banmankhi bazar">
                              Banmankhi bazar
                            </option>
                            <option value="Banswara">Banswara</option>
                            <option value="Bapatla">Bapatla</option>
                            <option value="Barahiya">Barahiya</option>
                            <option value="Barakar">Barakar</option>
                            <option value="Baramati">Baramati</option>
                            <option value="Baramula">Baramula</option>
                            <option value="Baran">Baran</option>
                            <option value="Barasat">Barasat</option>
                            <option value="Barauli">Barauli</option>
                            <option value="Barbigha">Barbigha</option>
                            <option value="Barbil">Barbil</option>
                            <option value="Bardhaman">Bardhaman</option>
                            <option value="Bareilly">Bareilly</option>
                            <option value="Bargarh">Bargarh</option>
                            <option value="Bargarh">Bargarh</option>
                            <option value="Barh">Barh</option>
                            <option value="Baripada">Baripada</option>
                            <option value="Barmer">Barmer</option>
                            <option value="Barnala">Barnala</option>
                            <option value="Barpeta">Barpeta</option>
                            <option value="Barpeta road">Barpeta road</option>
                            <option value="Barughutu">Barughutu</option>
                            <option value="Barwala">Barwala</option>
                            <option value="Basudebpur">Basudebpur</option>
                            <option value="Batala">Batala</option>
                            <option value="Bathinda">Bathinda</option>
                            <option value="Bazpur">Bazpur</option>
                            <option value="Begusarai">Begusarai</option>
                            <option value="Behea">Behea</option>
                            <option value="Belgaum">Belgaum</option>
                            <option value="Bellampalle">Bellampalle</option>
                            <option value="Bellary">Bellary</option>
                            <option value="Belpahar">Belpahar</option>
                            <option value="Bemetra">Bemetra</option>
                            <option value="Bethamcherla">Bethamcherla</option>
                            <option value="Bettiah">Bettiah</option>
                            <option value="Betul">Betul</option>
                            <option value="Bhabua">Bhabua</option>
                            <option value="Bhadrachalam">Bhadrachalam</option>
                            <option value="Bhadrak">Bhadrak</option>
                            <option value="Bhagalpur">Bhagalpur</option>
                            <option value="Bhagha purana">Bhagha purana</option>
                            <option value="Bhainsa">Bhainsa</option>
                            <option value="Bharuch">Bharuch</option>
                            <option value="Bhatapara">Bhatapara</option>
                            <option value="Bhavani">Bhavani</option>
                            <option value="Bhavnagar">Bhavnagar</option>
                            <option value="Bhawanipatna">Bhawanipatna</option>
                            <option value="Bheemunipatnam">
                              Bheemunipatnam
                            </option>
                            <option value="Bhimavaram">Bhimavaram</option>
                            <option value="Bhiwani">Bhiwani</option>
                            <option value="Bhongir">Bhongir</option>
                            <option value="Bhopal">Bhopal</option>
                            <option value="Bhuban">Bhuban</option>
                            <option value="Bhubaneswar">Bhubaneswar</option>
                            <option value="Bhuj">Bhuj</option>
                            <option value="Bidhan nagar">Bidhan nagar</option>
                            <option value="Bihar sharif">Bihar sharif</option>
                            <option value="Bikaner">Bikaner</option>
                            <option value="Bikramganj">Bikramganj</option>
                            <option value="Bilasipara">Bilasipara</option>
                            <option value="Bilaspur">Bilaspur</option>
                            <option value="Bilaspur">Bilaspur</option>
                            <option value="Biramitrapur">Biramitrapur</option>
                            <option value="Birgaon">Birgaon</option>
                            <option value="Bobbili">Bobbili</option>
                            <option value="Bodh gaya">Bodh gaya</option>
                            <option value="Bodhan">Bodhan</option>
                            <option value="Bokaro steel city">
                              Bokaro steel city
                            </option>
                            <option value="Bomdila">Bomdila</option>
                            <option value="Bongaigaon">Bongaigaon</option>
                            <option value="Brahmapur">Brahmapur</option>
                            <option value="Brajrajnagar">Brajrajnagar</option>
                            <option value="Budhlada">Budhlada</option>
                            <option value="Burhanpur">Burhanpur</option>
                            <option value="Buxar">Buxar</option>
                            <option value="Byasanagar">Byasanagar</option>
                            <option value="Calcutta">Calcutta</option>
                            <option value="Cambay">Cambay</option>
                            <option value="Chaibasa">Chaibasa</option>
                            <option value="Chakradharpur">Chakradharpur</option>
                            <option value="Chalakudy">Chalakudy</option>
                            <option value="Chalisgaon">Chalisgaon</option>
                            <option value="Chamba">Chamba</option>
                            <option value="Chamba">Chamba</option>
                            <option value="Champa">Champa</option>
                            <option value="Champhai">Champhai</option>
                            <option value="Chamrajnagar">Chamrajnagar</option>
                            <option value="Chandan bara">Chandan bara</option>
                            <option value="Chandausi">Chandausi</option>
                            <option value="Chandigarh">Chandigarh</option>
                            <option value="Chandrapura">Chandrapura</option>
                            <option value="Changanassery">Changanassery</option>
                            <option value="Chanpatia">Chanpatia</option>
                            <option value="Charkhi dadri">Charkhi dadri</option>
                            <option value="Chatra">Chatra</option>
                            <option value="Cheeka">Cheeka</option>
                            <option value="Chendamangalam">
                              Chendamangalam
                            </option>
                            <option value="Chengalpattu">Chengalpattu</option>
                            <option value="Chengannur">Chengannur</option>
                            <option value="Chennai">Chennai</option>
                            <option value="Chennai">Chennai</option>
                            <option value="Cherthala">Cherthala</option>
                            <option value="Cheruthazham">Cheruthazham</option>
                            <option value="Chhapra">Chhapra</option>
                            <option value="Chhatarpur">Chhatarpur</option>
                            <option value="Chikkaballapur">
                              Chikkaballapur
                            </option>
                            <option value="Chikmagalur">Chikmagalur</option>
                            <option value="Chilakaluripet">
                              Chilakaluripet
                            </option>
                            <option value="Chinchani">Chinchani</option>
                            <option value="Chinna salem">Chinna salem</option>
                            <option value="Chinsura">Chinsura</option>
                            <option value="Chintamani">Chintamani</option>
                            <option value="Chirala">Chirala</option>
                            <option value="Chirkunda">Chirkunda</option>
                            <option value="Chirmiri">Chirmiri</option>
                            <option value="Chitradurga">Chitradurga</option>
                            <option value="Chittoor">Chittoor</option>
                            <option value="Chittur-thathamangalam">
                              Chittur-thathamangalam
                            </option>
                            <option value="Chockli">Chockli</option>
                            <option value="Churi">Churi</option>
                            <option value="Coimbatore">Coimbatore</option>
                            <option value="Coimbatore">Coimbatore</option>
                            <option value="Colgong">Colgong</option>
                            <option value="Contai">Contai</option>
                            <option value="Cooch behar">Cooch behar</option>
                            <option value="Coonoor">Coonoor</option>
                            <option value="Cuddalore">Cuddalore</option>
                            <option value="Cuddapah">Cuddapah</option>
                            <option value="Curchorem cacora">
                              Curchorem cacora
                            </option>
                            <option value="Cuttack">Cuttack</option>
                            <option value="Dabra">Dabra</option>
                            <option value="Dadri">Dadri</option>
                            <option value="Dahod">Dahod</option>
                            <option value="Dalhousie">Dalhousie</option>
                            <option value="Dalli-rajhara">Dalli-rajhara</option>
                            <option value="Dalsinghsarai">Dalsinghsarai</option>
                            <option value="Daltonganj">Daltonganj</option>
                            <option value="Daman and diu">Daman and diu</option>
                            <option value="Daman and diu">Daman and diu</option>
                            <option value="Darbhanga">Darbhanga</option>
                            <option value="Darjeeling">Darjeeling</option>
                            <option value="Dasua">Dasua</option>
                            <option value="Datia">Datia</option>
                            <option value="Daudnagar">Daudnagar</option>
                            <option value="Davanagere">Davanagere</option>
                            <option value="Debagarh">Debagarh</option>
                            <option value="Deesa">Deesa</option>
                            <option value="Dehradun">Dehradun</option>
                            <option value="Dehri-on-sone">Dehri-on-sone</option>
                            <option value="Delhi">Delhi</option>
                            <option value="Deoghar">Deoghar</option>
                            <option value="Deoria">Deoria</option>
                            <option value="Devarakonda">Devarakonda</option>
                            <option value="Devgarh">Devgarh</option>
                            <option value="Dewas">Dewas</option>
                            <option value="Dhaka">Dhaka</option>
                            <option value="Dhamtari">Dhamtari</option>
                            <option value="Dhanbad">Dhanbad</option>
                            <option value="Dhar">Dhar</option>
                            <option value="Dharampur">Dharampur</option>
                            <option value="Dharamsala">Dharamsala</option>
                            <option value="Dharmanagar">Dharmanagar</option>
                            <option value="Dharmapuri">Dharmapuri</option>
                            <option value="Dharmavaram">Dharmavaram</option>
                            <option value="Dharwad">Dharwad</option>
                            <option value="Dhekiajuli">Dhekiajuli</option>
                            <option value="Dhenkanal">Dhenkanal</option>
                            <option value="Dholka">Dholka</option>
                            <option value="Dhubri">Dhubri</option>
                            <option value="Dhule">Dhule</option>
                            <option value="Dhuri">Dhuri</option>
                            <option value="Dibrugarh">Dibrugarh</option>
                            <option value="Digboi">Digboi</option>
                            <option value="Dighwara">Dighwara</option>
                            <option value="Dimapur">Dimapur</option>
                            <option value="Dinanagar">Dinanagar</option>
                            <option value="Dindigul">Dindigul</option>
                            <option value="Diphu">Diphu</option>
                            <option value="Dipka">Dipka</option>
                            <option value="Dispur">Dispur</option>
                            <option value="Dombivli">Dombivli</option>
                            <option value="Dongargarh">Dongargarh</option>
                            <option value="Dumka">Dumka</option>
                            <option value="Dumraon">Dumraon</option>
                            <option value="Durg-bhilai nagar">
                              Durg-bhilai nagar
                            </option>
                            <option value="Durgapur">Durgapur</option>
                            <option value="Durgapur">Durgapur</option>
                            <option value="Ellenabad 2">Ellenabad 2</option>
                            <option value="Eluru">Eluru</option>
                            <option value="Erattupetta">Erattupetta</option>
                            <option value="Erode">Erode</option>
                            <option value="Etawah">Etawah</option>
                            <option value="Faridabad">Faridabad</option>
                            <option value="Faridkot">Faridkot</option>
                            <option value="Farooqnagar">Farooqnagar</option>
                            <option value="Fatehabad">Fatehabad</option>
                            <option value="Fatehabad">Fatehabad</option>
                            <option value="Fatehabad">Fatehabad</option>
                            <option value="Fatehpur">Fatehpur</option>
                            <option value="Fatehpur">Fatehpur</option>
                            <option value="Fatehpur">Fatehpur</option>
                            <option value="Fatwah">Fatwah</option>
                            <option value="Fazilka">Fazilka</option>
                            <option value="Firozpur">Firozpur</option>
                            <option value="Firozpur cantt.">
                              Firozpur cantt.
                            </option>
                            <option value="Forbesganj">Forbesganj</option>
                            <option value="Gadag">Gadag</option>
                            <option value="Gadwal">Gadwal</option>
                            <option value="Ganaur">Ganaur</option>
                            <option value="Gandhinagar">Gandhinagar</option>
                            <option value="Gangtok">Gangtok</option>
                            <option value="Garhwa">Garhwa</option>
                            <option value="Gauripur">Gauripur</option>
                            <option value="Gaya">Gaya</option>
                            <option value="Gharaunda">Gharaunda</option>
                            <option value="Ghatshila">Ghatshila</option>
                            <option value="Giddarbaha">Giddarbaha</option>
                            <option value="Giridih">Giridih</option>
                            <option value="Goalpara">Goalpara</option>
                            <option value="Gobindgarh">Gobindgarh</option>
                            <option value="Gobranawapara">Gobranawapara</option>
                            <option value="Godda">Godda</option>
                            <option value="Godhra">Godhra</option>
                            <option value="Gogri jamalpur">
                              Gogri jamalpur
                            </option>
                            <option value="Gohana">Gohana</option>
                            <option value="Golaghat">Golaghat</option>
                            <option value="Gomoh">Gomoh</option>
                            <option value="Gooty">Gooty</option>
                            <option value="Gopalganj">Gopalganj</option>
                            <option value="Greater noida">Greater noida</option>
                            <option value="Gudalur">Gudalur</option>
                            <option value="Gudalur">Gudalur</option>
                            <option value="Gudalur">Gudalur</option>
                            <option value="Gudivada">Gudivada</option>
                            <option value="Gudur">Gudur</option>
                            <option value="Gulbarga">Gulbarga</option>
                            <option value="Gumia">Gumia</option>
                            <option value="Gumla">Gumla</option>
                            <option value="Gundlupet">Gundlupet</option>
                            <option value="Guntakal">Guntakal</option>
                            <option value="Guntur">Guntur</option>
                            <option value="Gunupur">Gunupur</option>
                            <option value="Gurdaspur">Gurdaspur</option>
                            <option value="Gurgaon">Gurgaon</option>
                            <option value="Guruvayoor">Guruvayoor</option>
                            <option value="Guwahati">Guwahati</option>
                            <option value="Gwalior">Gwalior</option>
                            <option value="Haflong">Haflong</option>
                            <option value="Haibat(yamuna nagar)">
                              Haibat(yamuna nagar)
                            </option>
                            <option value="Hailakandi">Hailakandi</option>
                            <option value="Hajipur">Hajipur</option>
                            <option value="Haldia">Haldia</option>
                            <option value="Haldwani">Haldwani</option>
                            <option value="Hamirpur">Hamirpur</option>
                            <option value="Hamirpur">Hamirpur</option>
                            <option value="Hansi">Hansi</option>
                            <option value="Hanuman junction">
                              Hanuman junction
                            </option>
                            <option value="Hardoi">Hardoi</option>
                            <option value="Haridwar">Haridwar</option>
                            <option value="Hassan">Hassan</option>
                            <option value="Hazaribag">Hazaribag</option>
                            <option value="Hilsa">Hilsa</option>
                            <option value="Himatnagar">Himatnagar</option>
                            <option value="Hindupur">Hindupur</option>
                            <option value="Hinjilicut">Hinjilicut</option>
                            <option value="Hisar">Hisar</option>
                            <option value="Hisua">Hisua</option>
                            <option value="Hodal">Hodal</option>
                            <option value="Hojai">Hojai</option>
                            <option value="Hoshiarpur">Hoshiarpur</option>
                            <option value="Hospet">Hospet</option>
                            <option value="Howrah">Howrah</option>
                            <option value="Hubli">Hubli</option>
                            <option value="Hussainabad">Hussainabad</option>
                            <option value="Hyderabad">Hyderabad</option>
                            <option value="Ichalkaranji">Ichalkaranji</option>
                            <option value="Ichchapuram">Ichchapuram</option>
                            <option value="Idar">Idar</option>
                            <option value="Imphal">Imphal</option>
                            <option value="Indore">Indore</option>
                            <option value="Indranagar">Indranagar</option>
                            <option value="Irinjalakuda">Irinjalakuda</option>
                            <option value="Islampur">Islampur</option>
                            <option value="Islampur">Islampur</option>
                            <option value="Itanagar">Itanagar</option>
                            <option value="Itarsi">Itarsi</option>
                            <option value="Jabalpur">Jabalpur</option>
                            <option value="Jagatsinghapur">
                              Jagatsinghapur
                            </option>
                            <option value="Jagdalpur">Jagdalpur</option>
                            <option value="Jagdispur">Jagdispur</option>
                            <option value="Jaggaiahpet">Jaggaiahpet</option>
                            <option value="Jagraon">Jagraon</option>
                            <option value="Jagtial">Jagtial</option>
                            <option value="Jaipur">Jaipur</option>
                            <option value="Jaisalmer">Jaisalmer</option>
                            <option value="Jaitu">Jaitu</option>
                            <option value="Jajapur">Jajapur</option>
                            <option value="Jajmau">Jajmau</option>
                            <option value="Jalalabad">Jalalabad</option>
                            <option value="Jalandhar">Jalandhar</option>
                            <option value="Jalandhar cantt.">
                              Jalandhar cantt.
                            </option>
                            <option value="Jaleswar">Jaleswar</option>
                            <option value="Jalna">Jalna</option>
                            <option value="Jamalpur">Jamalpur</option>
                            <option value="Jammalamadugu">Jammalamadugu</option>
                            <option value="Jammu">Jammu</option>
                            <option value="Jamnagar">Jamnagar</option>
                            <option value="Jamshedpur">Jamshedpur</option>
                            <option value="Jamtara">Jamtara</option>
                            <option value="Jamui">Jamui</option>
                            <option value="Jandiala">Jandiala</option>
                            <option value="Jangaon">Jangaon</option>
                            <option value="Janjgir">Janjgir</option>
                            <option value="Jashpurnagar">Jashpurnagar</option>
                            <option value="Jaspur">Jaspur</option>
                            <option value="Jatani">Jatani</option>
                            <option value="Jaunpur">Jaunpur</option>
                            <option value="Jehanabad">Jehanabad</option>
                            <option value="Jeypur">Jeypur</option>
                            <option value="Jhajha">Jhajha</option>
                            <option value="Jhajjar">Jhajjar</option>
                            <option value="Jhanjharpur">Jhanjharpur</option>
                            <option value="Jhansi">Jhansi</option>
                            <option value="Jhargram">Jhargram</option>
                            <option value="Jharsuguda">Jharsuguda</option>
                            <option value="Jhumri tilaiya">
                              Jhumri tilaiya
                            </option>
                            <option value="Jind">Jind</option>
                            <option value="Joda">Joda</option>
                            <option value="Jodhpur">Jodhpur</option>
                            <option value="Jogabani">Jogabani</option>
                            <option value="Jogendranagar">Jogendranagar</option>
                            <option value="Jorhat">Jorhat</option>
                            <option value="Jowai">Jowai</option>
                            <option value="Junagadh">Junagadh</option>
                            <option value="Kadapa">Kadapa</option>
                            <option value="Kadi">Kadi</option>
                            <option value="Kadiri">Kadiri</option>
                            <option value="Kadirur">Kadirur</option>
                            <option value="Kagaznagar">Kagaznagar</option>
                            <option value="Kailasahar">Kailasahar</option>
                            <option value="Kaithal">Kaithal</option>
                            <option value="Kakching">Kakching</option>
                            <option value="Kakinada">Kakinada</option>
                            <option value="Kalan wali">Kalan wali</option>
                            <option value="Kalavad">Kalavad</option>
                            <option value="Kalka">Kalka</option>
                            <option value="Kalliasseri">Kalliasseri</option>
                            <option value="Kalol">Kalol</option>
                            <option value="Kalpetta">Kalpetta</option>
                            <option value="Kalpi">Kalpi</option>
                            <option value="Kalyan">Kalyan</option>
                            <option value="Kalyandurg">Kalyandurg</option>
                            <option value="Kamareddy">Kamareddy</option>
                            <option value="Kanchipuram">Kanchipuram</option>
                            <option value="Kandukur">Kandukur</option>
                            <option value="Kanhangad">Kanhangad</option>
                            <option value="Kanjikkuzhi">Kanjikkuzhi</option>
                            <option value="Kanker">Kanker</option>
                            <option value="Kannur">Kannur</option>
                            <option value="Kanpur">Kanpur</option>
                            <option value="Kantabanji">Kantabanji</option>
                            <option value="Kanti">Kanti</option>
                            <option value="Kapadvanj">Kapadvanj</option>
                            <option value="Kapurthala">Kapurthala</option>
                            <option value="Karaikal">Karaikal</option>
                            <option value="Karaikudi">Karaikudi</option>
                            <option value="Karanjia">Karanjia</option>
                            <option value="Karimganj">Karimganj</option>
                            <option value="Karimnagar">Karimnagar</option>
                            <option value="Karjan">Karjan</option>
                            <option value="Karkala">Karkala</option>
                            <option value="Karnal">Karnal</option>
                            <option value="Karoran">Karoran</option>
                            <option value="Kartarpur">Kartarpur</option>
                            <option value="Karungal">Karungal</option>
                            <option value="Karur">Karur</option>
                            <option value="Karwar">Karwar</option>
                            <option value="Kasaragod">Kasaragod</option>
                            <option value="Kashipur">Kashipur</option>
                            <option value="Kathua">Kathua</option>
                            <option value="Katihar">Katihar</option>
                            <option value="Katni">Katni</option>
                            <option value="Kavali">Kavali</option>
                            <option value="Kavaratti">Kavaratti</option>
                            <option value="Kawardha">Kawardha</option>
                            <option value="Kayamkulam">Kayamkulam</option>
                            <option value="Kendrapara">Kendrapara</option>
                            <option value="Kendujhar">Kendujhar</option>
                            <option value="Keshod">Keshod</option>
                            <option value="Khagaria">Khagaria</option>
                            <option value="Khambhalia">Khambhalia</option>
                            <option value="Khambhat">Khambhat</option>
                            <option value="Khammam">Khammam</option>
                            <option value="Khanna">Khanna</option>
                            <option value="Kharagpur">Kharagpur</option>
                            <option value="Kharagpur">Kharagpur</option>
                            <option value="Kharar">Kharar</option>
                            <option value="Kheda">Kheda</option>
                            <option value="Khedbrahma">Khedbrahma</option>
                            <option value="Kheralu">Kheralu</option>
                            <option value="Khordha">Khordha</option>
                            <option value="Khowai">Khowai</option>
                            <option value="Khunti">Khunti</option>
                            <option value="Kichha">Kichha</option>
                            <option value="Kishanganj">Kishanganj</option>
                            <option value="Kochi">Kochi</option>
                            <option value="Kodinar">Kodinar</option>
                            <option value="Kodungallur">Kodungallur</option>
                            <option value="Kohima">Kohima</option>
                            <option value="Kokrajhar">Kokrajhar</option>
                            <option value="Kolar">Kolar</option>
                            <option value="Kolhapur">Kolhapur</option>
                            <option value="Kolkata">Kolkata</option>
                            <option value="Kollam">Kollam</option>
                            <option value="Kollankodu">Kollankodu</option>
                            <option value="Kondagaon">Kondagaon</option>
                            <option value="Koothuparamba">Koothuparamba</option>
                            <option value="Koraput">Koraput</option>
                            <option value="Koratla">Koratla</option>
                            <option value="Korba">Korba</option>
                            <option value="Kot kapura">Kot kapura</option>
                            <option value="Kota">Kota</option>
                            <option value="Kota">Kota</option>
                            <option value="Kota">Kota</option>
                            <option value="Kotdwara">Kotdwara</option>
                            <option value="Kothagudem">Kothagudem</option>
                            <option value="Kothamangalam">Kothamangalam</option>
                            <option value="Kothapeta">Kothapeta</option>
                            <option value="Kotma">Kotma</option>
                            <option value="Kottayam">Kottayam</option>
                            <option value="Kovvur">Kovvur</option>
                            <option value="Kozhikode">Kozhikode</option>
                            <option value="Kunnamkulam">Kunnamkulam</option>
                            <option value="Kurali">Kurali</option>
                            <option value="Kurnool">Kurnool</option>
                            <option value="Kyathampalle">Kyathampalle</option>
                            <option value="Lachhmangarh">Lachhmangarh</option>
                            <option value="Ladnu">Ladnu</option>
                            <option value="Ladwa">Ladwa</option>
                            <option value="Lahar">Lahar</option>
                            <option value="Laharpur">Laharpur</option>
                            <option value="Lakheri">Lakheri</option>
                            <option value="Lakhimpur">Lakhimpur</option>
                            <option value="Lakhisarai">Lakhisarai</option>
                            <option value="Lakshmeshwar">Lakshmeshwar</option>
                            <option value="Lal gopalganj nindaura">
                              Lal gopalganj nindaura
                            </option>
                            <option value="Lalganj">Lalganj</option>
                            <option value="Lalganj">Lalganj</option>
                            <option value="Lalgudi">Lalgudi</option>
                            <option value="Lalitpur">Lalitpur</option>
                            <option value="Lalsot">Lalsot</option>
                            <option value="Lanka">Lanka</option>
                            <option value="Lar">Lar</option>
                            <option value="Lathi">Lathi</option>
                            <option value="Latur">Latur</option>
                            <option value="Leh">Leh</option>
                            <option value="Lilong">Lilong</option>
                            <option value="Limbdi">Limbdi</option>
                            <option value="Lingsugur">Lingsugur</option>
                            <option value="Loha">Loha</option>
                            <option value="Lohardaga">Lohardaga</option>
                            <option value="Lonar">Lonar</option>
                            <option value="Lonavla">Lonavla</option>
                            <option value="Longowal">Longowal</option>
                            <option value="Loni">Loni</option>
                            <option value="Losal">Losal</option>
                            <option value="Lucknow">Lucknow</option>
                            <option value="Ludhiana">Ludhiana</option>
                            <option value="Lumding">Lumding</option>
                            <option value="Lunawada">Lunawada</option>
                            <option value="Lundi">Lundi</option>
                            <option value="Lunglei">Lunglei</option>
                            <option value="Macherla">Macherla</option>
                            <option value="Machilipatnam">Machilipatnam</option>
                            <option value="Madanapalle">Madanapalle</option>
                            <option value="Maddur">Maddur</option>
                            <option value="Madgaon">Madgaon</option>
                            <option value="Madhepura">Madhepura</option>
                            <option value="Madhubani">Madhubani</option>
                            <option value="Madhugiri">Madhugiri</option>
                            <option value="Madhupur">Madhupur</option>
                            <option value="Madikeri">Madikeri</option>
                            <option value="Madurai">Madurai</option>
                            <option value="Magadi">Magadi</option>
                            <option value="Mahad">Mahad</option>
                            <option value="Mahalingpur">Mahalingpur</option>
                            <option value="Maharajganj">Maharajganj</option>
                            <option value="Maharajpur">Maharajpur</option>
                            <option value="Mahasamund">Mahasamund</option>
                            <option value="Mahbubnagar">Mahbubnagar</option>
                            <option value="Mahe">Mahe</option>
                            <option value="Mahendragarh">Mahendragarh</option>
                            <option value="Mahendragarh">Mahendragarh</option>
                            <option value="Mahesana">Mahesana</option>
                            <option value="Mahidpur">Mahidpur</option>
                            <option value="Mahnar bazar">Mahnar bazar</option>
                            <option value="Mahuli">Mahuli</option>
                            <option value="Mahuva">Mahuva</option>
                            <option value="Maihar">Maihar</option>
                            <option value="Mainaguri">Mainaguri</option>
                            <option value="Makhdumpur">Makhdumpur</option>
                            <option value="Makrana">Makrana</option>
                            <option value="Mal">Mal</option>
                            <option value="Malajkhand">Malajkhand</option>
                            <option value="Malappuram">Malappuram</option>
                            <option value="Malavalli">Malavalli</option>
                            <option value="Malegaon">Malegaon</option>
                            <option value="Malerkotla">Malerkotla</option>
                            <option value="Malkangiri">Malkangiri</option>
                            <option value="Malkapur">Malkapur</option>
                            <option value="Malout">Malout</option>
                            <option value="Malpura">Malpura</option>
                            <option value="Malur">Malur</option>
                            <option value="Manasa">Manasa</option>
                            <option value="Manavadar">Manavadar</option>
                            <option value="Manawar">Manawar</option>
                            <option value="Manchar">Manchar</option>
                            <option value="Mancherial">Mancherial</option>
                            <option value="Mandalgarh">Mandalgarh</option>
                            <option value="Mandamarri">Mandamarri</option>
                            <option value="Mandapeta">Mandapeta</option>
                            <option value="Mandawa">Mandawa</option>
                            <option value="Mandi">Mandi</option>
                            <option value="Mandi dabwali">Mandi dabwali</option>
                            <option value="Mandideep">Mandideep</option>
                            <option value="Mandla">Mandla</option>
                            <option value="Mandsaur">Mandsaur</option>
                            <option value="Mandvi">Mandvi</option>
                            <option value="Mandya">Mandya</option>
                            <option value="Maner">Maner</option>
                            <option value="Mangaldoi">Mangaldoi</option>
                            <option value="Mangalore">Mangalore</option>
                            <option value="Mangalvedhe">Mangalvedhe</option>
                            <option value="Manglaur">Manglaur</option>
                            <option value="Mangrol">Mangrol</option>
                            <option value="Mangrol">Mangrol</option>
                            <option value="Mangrulpir">Mangrulpir</option>
                            <option value="Manihari">Manihari</option>
                            <option value="Manjlegaon">Manjlegaon</option>
                            <option value="Mankachar">Mankachar</option>
                            <option value="Manmad">Manmad</option>
                            <option value="Mansa">Mansa</option>
                            <option value="Mansa">Mansa</option>
                            <option value="Manuguru">Manuguru</option>
                            <option value="Manvi">Manvi</option>
                            <option value="Manwath">Manwath</option>
                            <option value="Mapusa">Mapusa</option>
                            <option value="Margao">Margao</option>
                            <option value="Margherita">Margherita</option>
                            <option value="Marhaura">Marhaura</option>
                            <option value="Mariani">Mariani</option>
                            <option value="Marigaon">Marigaon</option>
                            <option value="Markapur">Markapur</option>
                            <option value="Marmagao">Marmagao</option>
                            <option value="Masaurhi">Masaurhi</option>
                            <option value="Mathabhanga">Mathabhanga</option>
                            <option value="Mathura">Mathura</option>
                            <option value="Mattannur">Mattannur</option>
                            <option value="Mauganj">Mauganj</option>
                            <option value="Maur">Maur</option>
                            <option value="Mavelikkara">Mavelikkara</option>
                            <option value="Mavoor">Mavoor</option>
                            <option value="Mayang imphal">Mayang imphal</option>
                            <option value="Medak">Medak</option>
                            <option value="Medinipur">Medinipur</option>
                            <option value="Meerut">Meerut</option>
                            <option value="Mehkar">Mehkar</option>
                            <option value="Mehmedabad">Mehmedabad</option>
                            <option value="Memari">Memari</option>
                            <option value="Merta city">Merta city</option>
                            <option value="Mhaswad">Mhaswad</option>
                            <option value="Mhow cantonment">
                              Mhow cantonment
                            </option>
                            <option value="Mhowgaon">Mhowgaon</option>
                            <option value="Mihijam">Mihijam</option>
                            <option value="Miraj">Miraj</option>
                            <option value="Mirganj">Mirganj</option>
                            <option value="Miryalaguda">Miryalaguda</option>
                            <option value="Modasa">Modasa</option>
                            <option value="Modinagar">Modinagar</option>
                            <option value="Moga">Moga</option>
                            <option value="Mogalthur">Mogalthur</option>
                            <option value="Mohali">Mohali</option>
                            <option value="Mokameh">Mokameh</option>
                            <option value="Mokokchung">Mokokchung</option>
                            <option value="Monoharpur">Monoharpur</option>
                            <option value="Morena">Morena</option>
                            <option value="Morinda">Morinda</option>
                            <option value="Morshi">Morshi</option>
                            <option value="Morvi">Morvi</option>
                            <option value="Motihari">Motihari</option>
                            <option value="Motipur">Motipur</option>
                            <option value="Mount abu">Mount abu</option>
                            <option value="Mudalgi">Mudalgi</option>
                            <option value="Mudbidri">Mudbidri</option>
                            <option value="Muddebihal">Muddebihal</option>
                            <option value="Mudhol">Mudhol</option>
                            <option value="Mukerian">Mukerian</option>
                            <option value="Mukhed">Mukhed</option>
                            <option value="Muktsar">Muktsar</option>
                            <option value="Mul">Mul</option>
                            <option value="Mulbagal">Mulbagal</option>
                            <option value="Multai">Multai</option>
                            <option value="Mumbai">Mumbai</option>
                            <option value="Mundargi">Mundargi</option>
                            <option value="Mungeli">Mungeli</option>
                            <option value="Munger">Munger</option>
                            <option value="Muradnagar">Muradnagar</option>
                            <option value="Murliganj">Murliganj</option>
                            <option value="Murshidabad">Murshidabad</option>
                            <option value="Murtijapur">Murtijapur</option>
                            <option value="Murwara">Murwara</option>
                            <option value="Musabani">Musabani</option>
                            <option value="Mussoorie">Mussoorie</option>
                            <option value="Muvattupuzha">Muvattupuzha</option>
                            <option value="Muzaffarnagar">Muzaffarnagar</option>
                            <option value="Muzaffarpur">Muzaffarpur</option>
                            <option value="Mysore">Mysore</option>
                            <option value="Nabadwip">Nabadwip</option>
                            <option value="Nabarangapur">Nabarangapur</option>
                            <option value="Nabha">Nabha</option>
                            <option value="Nadbai">Nadbai</option>
                            <option value="Nadiad">Nadiad</option>
                            <option value="Nagaon">Nagaon</option>
                            <option value="Nagapattinam">Nagapattinam</option>
                            <option value="Nagar">Nagar</option>
                            <option value="Nagari">Nagari</option>
                            <option value="Nagarkurnool">Nagarkurnool</option>
                            <option value="Nagaur">Nagaur</option>
                            <option value="Nagda">Nagda</option>
                            <option value="Nagercoil">Nagercoil</option>
                            <option value="Nagina">Nagina</option>
                            <option value="Nagla">Nagla</option>
                            <option value="Nagpur">Nagpur</option>
                            <option value="Nahan">Nahan</option>
                            <option value="Naharlagun">Naharlagun</option>
                            <option value="Naihati">Naihati</option>
                            <option value="Naila janjgir">Naila janjgir</option>
                            <option value="Nainital">Nainital</option>
                            <option value="Nainpur">Nainpur</option>
                            <option value="Najibabad">Najibabad</option>
                            <option value="Nakodar">Nakodar</option>
                            <option value="Nakur">Nakur</option>
                            <option value="Nalasopara">Nalasopara</option>
                            <option value="Nalbari">Nalbari</option>
                            <option value="Namagiripettai">
                              Namagiripettai
                            </option>
                            <option value="Namakkal">Namakkal</option>
                            <option value="Nanded-waghala">
                              Nanded-waghala
                            </option>
                            <option value="Nandgaon">Nandgaon</option>
                            <option value="Nandivaram-guduvancheri">
                              Nandivaram-guduvancheri
                            </option>
                            <option value="Nandura">Nandura</option>
                            <option value="Nandurbar">Nandurbar</option>
                            <option value="Nandyal">Nandyal</option>
                            <option value="Nangal">Nangal</option>
                            <option value="Nanjangud">Nanjangud</option>
                            <option value="Nanjikottai">Nanjikottai</option>
                            <option value="Nanpara">Nanpara</option>
                            <option value="Narasapur">Narasapur</option>
                            <option value="Narasaraopet">Narasaraopet</option>
                            <option value="Naraura">Naraura</option>
                            <option value="Narayanpet">Narayanpet</option>
                            <option value="Nargund">Nargund</option>
                            <option value="Narkatiaganj">Narkatiaganj</option>
                            <option value="Narkhed">Narkhed</option>
                            <option value="Narnaul">Narnaul</option>
                            <option value="Narsinghgarh">Narsinghgarh</option>
                            <option value="Narsinghgarh">Narsinghgarh</option>
                            <option value="Narsipatnam">Narsipatnam</option>
                            <option value="Narwana">Narwana</option>
                            <option value="Nashik">Nashik</option>
                            <option value="Nasirabad">Nasirabad</option>
                            <option value="Natham">Natham</option>
                            <option value="Nathdwara">Nathdwara</option>
                            <option value="Naugachhia">Naugachhia</option>
                            <option value="Naugawan sadat">
                              Naugawan sadat
                            </option>
                            <option value="Nautanwa">Nautanwa</option>
                            <option value="Navalgund">Navalgund</option>
                            <option value="Navi mumbai">Navi mumbai</option>
                            <option value="Navsari">Navsari</option>
                            <option value="Nawabganj">Nawabganj</option>
                            <option value="Nawada">Nawada</option>
                            <option value="Nawalgarh">Nawalgarh</option>
                            <option value="Nawanshahr">Nawanshahr</option>
                            <option value="Nawapur">Nawapur</option>
                            <option value="Nedumangad">Nedumangad</option>
                            <option value="Neem-ka-thana">Neem-ka-thana</option>
                            <option value="Neemuch">Neemuch</option>
                            <option value="Nehtaur">Nehtaur</option>
                            <option value="Nelamangala">Nelamangala</option>
                            <option value="Nellikuppam">Nellikuppam</option>
                            <option value="Nellore">Nellore</option>
                            <option value="Nepanagar">Nepanagar</option>
                            <option value="Neyveli">Neyveli</option>
                            <option value="Neyyattinkara">Neyyattinkara</option>
                            <option value="Nidadavole">Nidadavole</option>
                            <option value="Nilanga">Nilanga</option>
                            <option value="Nimbahera">Nimbahera</option>
                            <option value="Nipani">Nipani</option>
                            <option value="Nirmal">Nirmal</option>
                            <option value="Niwai">Niwai</option>
                            <option value="Niwari">Niwari</option>
                            <option value="Nizamabad">Nizamabad</option>
                            <option value="Nohar">Nohar</option>
                            <option value="Noida">Noida</option>
                            <option value="Nokha">Nokha</option>
                            <option value="Nokha">Nokha</option>
                            <option value="Nongstoin">Nongstoin</option>
                            <option value="Noorpur">Noorpur</option>
                            <option value="North lakhimpur">
                              North lakhimpur
                            </option>
                            <option value="Nowgong">Nowgong</option>
                            <option value="Nowrozabad">Nowrozabad</option>
                            <option value="Nuzvid">Nuzvid</option>
                            <option value="O valley">O valley</option>
                            <option value="Obra">Obra</option>
                            <option value="Oddanchatram">Oddanchatram</option>
                            <option value="Ongole">Ongole</option>
                            <option value="Orai">Orai</option>
                            <option value="Osmanabad">Osmanabad</option>
                            <option value="Ottappalam">Ottappalam</option>
                            <option value="Ozar">Ozar</option>
                            <option value="P.n.patti">P.n.patti</option>
                            <option value="Pachora">Pachora</option>
                            <option value="Pachore">Pachore</option>
                            <option value="Pacode">Pacode</option>
                            <option value="Padmanabhapuram">
                              Padmanabhapuram
                            </option>
                            <option value="Padra">Padra</option>
                            <option value="Padrauna">Padrauna</option>
                            <option value="Paithan">Paithan</option>
                            <option value="Pakaur">Pakaur</option>
                            <option value="Palacole">Palacole</option>
                            <option value="Palai">Palai</option>
                            <option value="Palakkad">Palakkad</option>
                            <option value="Palani">Palani</option>
                            <option value="Palanpur">Palanpur</option>
                            <option value="Palasa kasibugga">
                              Palasa kasibugga
                            </option>
                            <option value="Palghar">Palghar</option>
                            <option value="Pali">Pali</option>
                            <option value="Pali">Pali</option>
                            <option value="Palia kalan">Palia kalan</option>
                            <option value="Palitana">Palitana</option>
                            <option value="Palladam">Palladam</option>
                            <option value="Pallapatti">Pallapatti</option>
                            <option value="Pallikonda">Pallikonda</option>
                            <option value="Palwal">Palwal</option>
                            <option value="Palwancha">Palwancha</option>
                            <option value="Panagar">Panagar</option>
                            <option value="Panagudi">Panagudi</option>
                            <option value="Panaji">Panaji</option>
                            <option value="Panchkula">Panchkula</option>
                            <option value="Panchla">Panchla</option>
                            <option value="Pandharkaoda">Pandharkaoda</option>
                            <option value="Pandharpur">Pandharpur</option>
                            <option value="Pandhurna">Pandhurna</option>
                            <option value="Pandua">Pandua</option>
                            <option value="Panipat">Panipat</option>
                            <option value="Panna">Panna</option>
                            <option value="Panniyannur">Panniyannur</option>
                            <option value="Panruti">Panruti</option>
                            <option value="Panvel">Panvel</option>
                            <option value="Pappinisseri">Pappinisseri</option>
                            <option value="Paradip">Paradip</option>
                            <option value="Paramakudi">Paramakudi</option>
                            <option value="Parangipettai">Parangipettai</option>
                            <option value="Parasi">Parasi</option>
                            <option value="Paravoor">Paravoor</option>
                            <option value="Parbhani">Parbhani</option>
                            <option value="Pardi">Pardi</option>
                            <option value="Parlakhemundi">Parlakhemundi</option>
                            <option value="Parli">Parli</option>
                            <option value="Parola">Parola</option>
                            <option value="Partur">Partur</option>
                            <option value="Parvathipuram">Parvathipuram</option>
                            <option value="Pasan">Pasan</option>
                            <option value="Paschim punropara">
                              Paschim punropara
                            </option>
                            <option value="Pasighat">Pasighat</option>
                            <option value="Patan">Patan</option>
                            <option value="Pathanamthitta">
                              Pathanamthitta
                            </option>
                            <option value="Pathankot">Pathankot</option>
                            <option value="Pathardi">Pathardi</option>
                            <option value="Pathri">Pathri</option>
                            <option value="Patiala">Patiala</option>
                            <option value="Patna">Patna</option>
                            <option value="Patran">Patran</option>
                            <option value="Patratu">Patratu</option>
                            <option value="Pattamundai">Pattamundai</option>
                            <option value="Patti">Patti</option>
                            <option value="Pattukkottai">Pattukkottai</option>
                            <option value="Patur">Patur</option>
                            <option value="Pauni">Pauni</option>
                            <option value="Pauri">Pauri</option>
                            <option value="Pavagada">Pavagada</option>
                            <option value="Payyannur">Payyannur</option>
                            <option value="Pedana">Pedana</option>
                            <option value="Peddapuram">Peddapuram</option>
                            <option value="Pehowa">Pehowa</option>
                            <option value="Pen">Pen</option>
                            <option value="Perambalur">Perambalur</option>
                            <option value="Peravurani">Peravurani</option>
                            <option value="Peringathur">Peringathur</option>
                            <option value="Perinthalmanna">
                              Perinthalmanna
                            </option>
                            <option value="Periyakulam">Periyakulam</option>
                            <option value="Periyasemur">Periyasemur</option>
                            <option value="Pernampattu">Pernampattu</option>
                            <option value="Perumbavoor">Perumbavoor</option>
                            <option value="Petlad">Petlad</option>
                            <option value="Phagwara">Phagwara</option>
                            <option value="Phalodi">Phalodi</option>
                            <option value="Phaltan">Phaltan</option>
                            <option value="Phillaur">Phillaur</option>
                            <option value="Phulabani">Phulabani</option>
                            <option value="Phulera">Phulera</option>
                            <option value="Phulpur">Phulpur</option>
                            <option value="Phusro">Phusro</option>
                            <option value="Pihani">Pihani</option>
                            <option value="Pilani">Pilani</option>
                            <option value="Pilibanga">Pilibanga</option>
                            <option value="Pilibhit">Pilibhit</option>
                            <option value="Pilkhuwa">Pilkhuwa</option>
                            <option value="Pindwara">Pindwara</option>
                            <option value="Pinjore">Pinjore</option>
                            <option value="Pipar city">Pipar city</option>
                            <option value="Pipariya">Pipariya</option>
                            <option value="Piro">Piro</option>
                            <option value="Pithampur">Pithampur</option>
                            <option value="Pithapuram">Pithapuram</option>
                            <option value="Pithoragarh">Pithoragarh</option>
                            <option value="Pollachi">Pollachi</option>
                            <option value="Polur">Polur</option>
                            <option value="Pondicherry">Pondicherry</option>
                            <option value="Pondur">Pondur</option>
                            <option value="Ponnani">Ponnani</option>
                            <option value="Ponneri">Ponneri</option>
                            <option value="Ponnur">Ponnur</option>
                            <option value="Porbandar">Porbandar</option>
                            <option value="Porsa">Porsa</option>
                            <option value="Port blair">Port blair</option>
                            <option value="Powayan">Powayan</option>
                            <option value="Prantij">Prantij</option>
                            <option value="Pratapgarh">Pratapgarh</option>
                            <option value="Pratapgarh">Pratapgarh</option>
                            <option value="Prithvipur">Prithvipur</option>
                            <option value="Proddatur">Proddatur</option>
                            <option value="Pudukkottai">Pudukkottai</option>
                            <option value="Pudupattinam">Pudupattinam</option>
                            <option value="Pukhrayan">Pukhrayan</option>
                            <option value="Pulgaon">Pulgaon</option>
                            <option value="Puliyankudi">Puliyankudi</option>
                            <option value="Punalur">Punalur</option>
                            <option value="Punch">Punch</option>
                            <option value="Pune">Pune</option>
                            <option value="Punganur">Punganur</option>
                            <option value="Punjaipugalur">Punjaipugalur</option>
                            <option value="Puranpur">Puranpur</option>
                            <option value="Puri">Puri</option>
                            <option value="Purna">Purna</option>
                            <option value="Purnia">Purnia</option>
                            <option value="Purquazi">Purquazi</option>
                            <option value="Purulia">Purulia</option>
                            <option value="Purwa">Purwa</option>
                            <option value="Pusad">Pusad</option>
                            <option value="Puttur">Puttur</option>
                            <option value="Puttur">Puttur</option>
                            <option value="Qadian">Qadian</option>
                            <option value="Quilandy">Quilandy</option>
                            <option value="Rabkavi banhatti">
                              Rabkavi banhatti
                            </option>
                            <option value="Radhanpur">Radhanpur</option>
                            <option value="Rae bareli">Rae bareli</option>
                            <option value="Rafiganj">Rafiganj</option>
                            <option value="Raghogarh-vijaypur">
                              Raghogarh-vijaypur
                            </option>
                            <option value="Raghunathpur">Raghunathpur</option>
                            <option value="Rahatgarh">Rahatgarh</option>
                            <option value="Rahuri">Rahuri</option>
                            <option value="Raichur">Raichur</option>
                            <option value="Raiganj">Raiganj</option>
                            <option value="Raigarh">Raigarh</option>
                            <option value="Raikot">Raikot</option>
                            <option value="Raipur">Raipur</option>
                            <option value="Rairangpur">Rairangpur</option>
                            <option value="Raisen">Raisen</option>
                            <option value="Raisinghnagar">Raisinghnagar</option>
                            <option value="Rajagangapur">Rajagangapur</option>
                            <option value="Rajahmundry">Rajahmundry</option>
                            <option value="Rajakhera">Rajakhera</option>
                            <option value="Rajaldesar">Rajaldesar</option>
                            <option value="Rajam">Rajam</option>
                            <option value="Rajampet">Rajampet</option>
                            <option value="Rajapalayam">Rajapalayam</option>
                            <option value="Rajauri">Rajauri</option>
                            <option value="Rajgarh">Rajgarh</option>
                            <option value="Rajgarh (alwar)">
                              Rajgarh (alwar)
                            </option>
                            <option value="Rajgarh (churu">
                              Rajgarh (churu
                            </option>
                            <option value="Rajgir">Rajgir</option>
                            <option value="Rajkot">Rajkot</option>
                            <option value="Rajnandgaon">Rajnandgaon</option>
                            <option value="Rajpipla">Rajpipla</option>
                            <option value="Rajpura">Rajpura</option>
                            <option value="Rajsamand">Rajsamand</option>
                            <option value="Rajula">Rajula</option>
                            <option value="Rajura">Rajura</option>
                            <option value="Ramachandrapuram">
                              Ramachandrapuram
                            </option>
                            <option value="Ramagundam">Ramagundam</option>
                            <option value="Ramanagaram">Ramanagaram</option>
                            <option value="Ramanathapuram">
                              Ramanathapuram
                            </option>
                            <option value="Ramdurg">Ramdurg</option>
                            <option value="Rameshwaram">Rameshwaram</option>
                            <option value="Ramganj mandi">Ramganj mandi</option>
                            <option value="Ramnagar">Ramnagar</option>
                            <option value="Ramnagar">Ramnagar</option>
                            <option value="Ramngarh">Ramngarh</option>
                            <option value="Ramngarh">Ramngarh</option>
                            <option value="Rampur">Rampur</option>
                            <option value="Rampur maniharan">
                              Rampur maniharan
                            </option>
                            <option value="Rampura phul">Rampura phul</option>
                            <option value="Rampurhat">Rampurhat</option>
                            <option value="Ramtek">Ramtek</option>
                            <option value="Ranaghat">Ranaghat</option>
                            <option value="Ranavav">Ranavav</option>
                            <option value="Ranchi">Ranchi</option>
                            <option value="Rangia">Rangia</option>
                            <option value="Rania">Rania</option>
                            <option value="Ranibennur">Ranibennur</option>
                            <option value="Rapar">Rapar</option>
                            <option value="Rasipuram">Rasipuram</option>
                            <option value="Rasra">Rasra</option>
                            <option value="Ratangarh">Ratangarh</option>
                            <option value="Rath">Rath</option>
                            <option value="Ratia">Ratia</option>
                            <option value="Ratlam">Ratlam</option>
                            <option value="Ratnagiri">Ratnagiri</option>
                            <option value="Rau">Rau</option>
                            <option value="Raurkela">Raurkela</option>
                            <option value="Raver">Raver</option>
                            <option value="Rawatbhata">Rawatbhata</option>
                            <option value="Rawatsar">Rawatsar</option>
                            <option value="Raxaul bazar">Raxaul bazar</option>
                            <option value="Rayachoti">Rayachoti</option>
                            <option value="Rayadurg">Rayadurg</option>
                            <option value="Rayagada">Rayagada</option>
                            <option value="Reengus">Reengus</option>
                            <option value="Rehli">Rehli</option>
                            <option value="Renigunta">Renigunta</option>
                            <option value="Renukoot">Renukoot</option>
                            <option value="Reoti">Reoti</option>
                            <option value="Repalle">Repalle</option>
                            <option value="Revelganj">Revelganj</option>
                            <option value="Rewa">Rewa</option>
                            <option value="Rewari">Rewari</option>
                            <option value="Rishikesh">Rishikesh</option>
                            <option value="Risod">Risod</option>
                            <option value="Robertsganj">Robertsganj</option>
                            <option value="Robertson pet">Robertson pet</option>
                            <option value="Rohtak">Rohtak</option>
                            <option value="Ron">Ron</option>
                            <option value="Roorkee">Roorkee</option>
                            <option value="Rosera">Rosera</option>
                            <option value="Rudauli">Rudauli</option>
                            <option value="Rudrapur">Rudrapur</option>
                            <option value="Rudrapur">Rudrapur</option>
                            <option value="Rupnagar">Rupnagar</option>
                            <option value="Sabalgarh">Sabalgarh</option>
                            <option value="Sadabad">Sadabad</option>
                            <option value="Sadalgi">Sadalgi</option>
                            <option value="Sadasivpet">Sadasivpet</option>
                            <option value="Sadri">Sadri</option>
                            <option value="Sadulshahar">Sadulshahar</option>
                            <option value="Safidon">Safidon</option>
                            <option value="Safipur">Safipur</option>
                            <option value="Sagar">Sagar</option>
                            <option value="Sagar">Sagar</option>
                            <option value="Sagwara">Sagwara</option>
                            <option value="Saharanpur">Saharanpur</option>
                            <option value="Saharsa">Saharsa</option>
                            <option value="Sahaspur">Sahaspur</option>
                            <option value="Sahaswan">Sahaswan</option>
                            <option value="Sahawar">Sahawar</option>
                            <option value="Sahibganj">Sahibganj</option>
                            <option value="Sahjanwa">Sahjanwa</option>
                            <option value="Saidpur">Saidpur</option>
                            <option value="Saiha">Saiha</option>
                            <option value="Sailu">Sailu</option>
                            <option value="Sainthia">Sainthia</option>
                            <option value="Sakleshpur">Sakleshpur</option>
                            <option value="Sakti">Sakti</option>
                            <option value="Salaya">Salaya</option>
                            <option value="Salem">Salem</option>
                            <option value="Salur">Salur</option>
                            <option value="Samalkha">Samalkha</option>
                            <option value="Samalkot">Samalkot</option>
                            <option value="Samana">Samana</option>
                            <option value="Samastipur">Samastipur</option>
                            <option value="Sambalpur">Sambalpur</option>
                            <option value="Sambhal">Sambhal</option>
                            <option value="Sambhar">Sambhar</option>
                            <option value="Samdhan">Samdhan</option>
                            <option value="Samthar">Samthar</option>
                            <option value="Sanand">Sanand</option>
                            <option value="Sanawad">Sanawad</option>
                            <option value="Sanchore">Sanchore</option>
                            <option value="Sandi">Sandi</option>
                            <option value="Sandila">Sandila</option>
                            <option value="Sandur">Sandur</option>
                            <option value="Sangamner">Sangamner</option>
                            <option value="Sangareddy">Sangareddy</option>
                            <option value="Sangaria">Sangaria</option>
                            <option value="Sangli">Sangli</option>
                            <option value="Sangole">Sangole</option>
                            <option value="Sangrur">Sangrur</option>
                            <option value="Sankarankoil">Sankarankoil</option>
                            <option value="Sankari">Sankari</option>
                            <option value="Sankeshwar">Sankeshwar</option>
                            <option value="Santipur">Santipur</option>
                            <option value="Sarangpur">Sarangpur</option>
                            <option value="Sardarshahar">Sardarshahar</option>
                            <option value="Sardhana">Sardhana</option>
                            <option value="Sarni">Sarni</option>
                            <option value="Sasaram">Sasaram</option>
                            <option value="Sasvad">Sasvad</option>
                            <option value="Satana">Satana</option>
                            <option value="Satara">Satara</option>
                            <option value="Sathyamangalam">
                              Sathyamangalam
                            </option>
                            <option value="Satna">Satna</option>
                            <option value="Sattenapalle">Sattenapalle</option>
                            <option value="Sattur">Sattur</option>
                            <option value="Saunda">Saunda</option>
                            <option value="Saundatti-yellamma">
                              Saundatti-yellamma
                            </option>
                            <option value="Sausar">Sausar</option>
                            <option value="Savanur">Savanur</option>
                            <option value="Savarkundla">Savarkundla</option>
                            <option value="Savner">Savner</option>
                            <option value="Sawai madhopur">
                              Sawai madhopur
                            </option>
                            <option value="Sawantwadi">Sawantwadi</option>
                            <option value="Sedam">Sedam</option>
                            <option value="Sehore">Sehore</option>
                            <option value="Sendhwa">Sendhwa</option>
                            <option value="Seohara">Seohara</option>
                            <option value="Seoni">Seoni</option>
                            <option value="Seoni-malwa">Seoni-malwa</option>
                            <option value="Shahabad">Shahabad</option>
                            <option value="Shahabad">Shahabad</option>
                            <option value="Shahabad">Shahabad</option>
                            <option value="Shahade">Shahade</option>
                            <option value="Shahbad">Shahbad</option>
                            <option value="Shahdol">Shahdol</option>
                            <option value="Shahganj">Shahganj</option>
                            <option value="Shahjahanpur">Shahjahanpur</option>
                            <option value="Shahpur">Shahpur</option>
                            <option value="Shahpura">Shahpura</option>
                            <option value="Shahpura">Shahpura</option>
                            <option value="Shajapur">Shajapur</option>
                            <option value="Shamgarh">Shamgarh</option>
                            <option value="Shamli">Shamli</option>
                            <option value="Shamsabad">Shamsabad</option>
                            <option value="Shamsabad">Shamsabad</option>
                            <option value="Shegaon">Shegaon</option>
                            <option value="Sheikhpura">Sheikhpura</option>
                            <option value="Shendurjana">Shendurjana</option>
                            <option value="Shenkottai">Shenkottai</option>
                            <option value="Sheoganj">Sheoganj</option>
                            <option value="Sheohar">Sheohar</option>
                            <option value="Sheopur">Sheopur</option>
                            <option value="Sherghati">Sherghati</option>
                            <option value="Sherkot">Sherkot</option>
                            <option value="Shiggaon">Shiggaon</option>
                            <option value="Shikapur">Shikapur</option>
                            <option value="Shikarpur">Shikarpur</option>
                            <option value="Shikohabad">Shikohabad</option>
                            <option value="Shillong">Shillong</option>
                            <option value="Shimla">Shimla</option>
                            <option value="Shimoga">Shimoga</option>
                            <option value="Shirdi">Shirdi</option>
                            <option value="Shirpur-warwade">
                              Shirpur-warwade
                            </option>
                            <option value="Shirur">Shirur</option>
                            <option value="Shishgarh">Shishgarh</option>
                            <option value="Shivpuri">Shivpuri</option>
                            <option value="Sholavandan">Sholavandan</option>
                            <option value="Sholingur">Sholingur</option>
                            <option value="Shoranur">Shoranur</option>
                            <option value="Shorapur">Shorapur</option>
                            <option value="Shrigonda">Shrigonda</option>
                            <option value="Shrirampur">Shrirampur</option>
                            <option value="Shrirangapattana">
                              Shrirangapattana
                            </option>
                            <option value="Shujalpur">Shujalpur</option>
                            <option value="Siana">Siana</option>
                            <option value="Sibsagar">Sibsagar</option>
                            <option value="Siddipet">Siddipet</option>
                            <option value="Sidhi">Sidhi</option>
                            <option value="Sidhpur">Sidhpur</option>
                            <option value="Sidlaghatta">Sidlaghatta</option>
                            <option value="Sihor">Sihor</option>
                            <option value="Sihora">Sihora</option>
                            <option value="Sikanderpur">Sikanderpur</option>
                            <option value="Sikandra rao">Sikandra rao</option>
                            <option value="Sikandrabad">Sikandrabad</option>
                            <option value="Sikar">Sikar</option>
                            <option value="Silao">Silao</option>
                            <option value="Silapathar">Silapathar</option>
                            <option value="Silchar">Silchar</option>
                            <option value="Siliguri">Siliguri</option>
                            <option value="Sillod">Sillod</option>
                            <option value="Silvassa">Silvassa</option>
                            <option value="Simdega">Simdega</option>
                            <option value="Sindgi">Sindgi</option>
                            <option value="Sindhnur">Sindhnur</option>
                            <option value="Singapur">Singapur</option>
                            <option value="Singrauli">Singrauli</option>
                            <option value="Sinnar">Sinnar</option>
                            <option value="Sira">Sira</option>
                            <option value="Sircilla">Sircilla</option>
                            <option value="Sirhind fatehgarh sahib">
                              Sirhind fatehgarh sahib
                            </option>
                            <option value="Sirkali">Sirkali</option>
                            <option value="Sirohi">Sirohi</option>
                            <option value="Sironj">Sironj</option>
                            <option value="Sirsa">Sirsa</option>
                            <option value="Sirsaganj">Sirsaganj</option>
                            <option value="Sirsi">Sirsi</option>
                            <option value="Sirsi">Sirsi</option>
                            <option value="Siruguppa">Siruguppa</option>
                            <option value="Sitamarhi">Sitamarhi</option>
                            <option value="Sitapur">Sitapur</option>
                            <option value="Sitarganj">Sitarganj</option>
                            <option value="Sivaganga">Sivaganga</option>
                            <option value="Sivagiri">Sivagiri</option>
                            <option value="Sivakasi">Sivakasi</option>
                            <option value="Siwan">Siwan</option>
                            <option value="Sohagpur">Sohagpur</option>
                            <option value="Sohna">Sohna</option>
                            <option value="Sojat">Sojat</option>
                            <option value="Solan">Solan</option>
                            <option value="Solapur">Solapur</option>
                            <option value="Sonamukhi">Sonamukhi</option>
                            <option value="Sonepur">Sonepur</option>
                            <option value="Songadh">Songadh</option>
                            <option value="Sonipat">Sonipat</option>
                            <option value="Sopore">Sopore</option>
                            <option value="Soro">Soro</option>
                            <option value="Soron">Soron</option>
                            <option value="Soyagaon">Soyagaon</option>
                            <option value="Sri madhopur">Sri madhopur</option>
                            <option value="Srikakulam">Srikakulam</option>
                            <option value="Srikalahasti">Srikalahasti</option>
                            <option value="Srinagar">Srinagar</option>
                            <option value="Srinivaspur">Srinivaspur</option>
                            <option value="Srirampore">Srirampore</option>
                            <option value="Srivilliputhur">
                              Srivilliputhur
                            </option>
                            <option value="Suar">Suar</option>
                            <option value="Sugauli">Sugauli</option>
                            <option value="Sujangarh">Sujangarh</option>
                            <option value="Sujanpur">Sujanpur</option>
                            <option value="Sultanganj">Sultanganj</option>
                            <option value="Sultanpur">Sultanpur</option>
                            <option value="Sumerpur">Sumerpur</option>
                            <option value="Sumerpur">Sumerpur</option>
                            <option value="Sunabeda">Sunabeda</option>
                            <option value="Sunam">Sunam</option>
                            <option value="Sundargarh">Sundargarh</option>
                            <option value="Sundarnagar">Sundarnagar</option>
                            <option value="Supaul">Supaul</option>
                            <option value="Surandai">Surandai</option>
                            <option value="Surat">Surat</option>
                            <option value="Suratgarh">Suratgarh</option>
                            <option value="Suri">Suri</option>
                            <option value="Suriyampalayam">
                              Suriyampalayam
                            </option>
                            <option value="Suryapet">Suryapet</option>
                            <option value="Tadepalligudem">
                              Tadepalligudem
                            </option>
                            <option value="Tadpatri">Tadpatri</option>
                            <option value="Taki">Taki</option>
                            <option value="Talaja">Talaja</option>
                            <option value="Talcher">Talcher</option>
                            <option value="Talegaon dabhade">
                              Talegaon dabhade
                            </option>
                            <option value="Talikota">Talikota</option>
                            <option value="Taliparamba">Taliparamba</option>
                            <option value="Talode">Talode</option>
                            <option value="Talwara">Talwara</option>
                            <option value="Tamluk">Tamluk</option>
                            <option value="Tanda">Tanda</option>
                            <option value="Tanda">Tanda</option>
                            <option value="Tandur">Tandur</option>
                            <option value="Tanuku">Tanuku</option>
                            <option value="Tarakeswar">Tarakeswar</option>
                            <option value="Tarana">Tarana</option>
                            <option value="Taranagar">Taranagar</option>
                            <option value="Taraori">Taraori</option>
                            <option value="Tarikere">Tarikere</option>
                            <option value="Tarn taran">Tarn taran</option>
                            <option value="Tasgaon">Tasgaon</option>
                            <option value="Tehri">Tehri</option>
                            <option value="Tekkalakota">Tekkalakota</option>
                            <option value="Tenali">Tenali</option>
                            <option value="Tenkasi">Tenkasi</option>
                            <option value="Tenu dam-cum- kathhara">
                              Tenu dam-cum- kathhara
                            </option>
                            <option value="Terdal">Terdal</option>
                            <option value="Tetri bazar">Tetri bazar</option>
                            <option value="Tezpur">Tezpur</option>
                            <option value="Thakurdwara">Thakurdwara</option>
                            <option value="Thammampatti">Thammampatti</option>
                            <option value="Thana bhawan">Thana bhawan</option>
                            <option value="Thanesar">Thanesar</option>
                            <option value="Thangadh">Thangadh</option>
                            <option value="Thanjavur">Thanjavur</option>
                            <option value="Tharad">Tharad</option>
                            <option value="Tharamangalam">Tharamangalam</option>
                            <option value="Tharangambadi">Tharangambadi</option>
                            <option value="Theni allinagaram">
                              Theni allinagaram
                            </option>
                            <option value="Thirumangalam">Thirumangalam</option>
                            <option value="Thirunindravur">
                              Thirunindravur
                            </option>
                            <option value="Thiruparappu">Thiruparappu</option>
                            <option value="Thirupuvanam">Thirupuvanam</option>
                            <option value="Thiruthuraipoondi">
                              Thiruthuraipoondi
                            </option>
                            <option value="Thiruvalla">Thiruvalla</option>
                            <option value="Thiruvallur">Thiruvallur</option>
                            <option value="Thiruvananthapuram">
                              Thiruvananthapuram
                            </option>
                            <option value="Thiruvarur">Thiruvarur</option>
                            <option value="Thodupuzha">Thodupuzha</option>
                            <option value="Thoothukudi">Thoothukudi</option>
                            <option value="Thoubal">Thoubal</option>
                            <option value="Thrissur">Thrissur</option>
                            <option value="Thuraiyur">Thuraiyur</option>
                            <option value="Tikamgarh">Tikamgarh</option>
                            <option value="Tilda newra">Tilda newra</option>
                            <option value="Tilhar">Tilhar</option>
                            <option value="Tindivanam">Tindivanam</option>
                            <option value="Tinsukia">Tinsukia</option>
                            <option value="Tiptur">Tiptur</option>
                            <option value="Tirora">Tirora</option>
                            <option value="Tiruchendur">Tiruchendur</option>
                            <option value="Tiruchengode">Tiruchengode</option>
                            <option value="Tiruchirappalli">
                              Tiruchirappalli
                            </option>
                            <option value="Tirukalukundram">
                              Tirukalukundram
                            </option>
                            <option value="Tirukkoyilur">Tirukkoyilur</option>
                            <option value="Tirunelveli">Tirunelveli</option>
                            <option value="Tirupathur">Tirupathur</option>
                            <option value="Tirupathur">Tirupathur</option>
                            <option value="Tirupati">Tirupati</option>
                            <option value="Tiruppur">Tiruppur</option>
                            <option value="Tirur">Tirur</option>
                            <option value="Tiruttani">Tiruttani</option>
                            <option value="Tiruvannamalai">
                              Tiruvannamalai
                            </option>
                            <option value="Tiruvethipuram">
                              Tiruvethipuram
                            </option>
                            <option value="Tirwaganj">Tirwaganj</option>
                            <option value="Titlagarh">Titlagarh</option>
                            <option value="Tittakudi">Tittakudi</option>
                            <option value="Todabhim">Todabhim</option>
                            <option value="Todaraisingh">Todaraisingh</option>
                            <option value="Tohana">Tohana</option>
                            <option value="Tonk">Tonk</option>
                            <option value="Tuensang">Tuensang</option>
                            <option value="Tuljapur">Tuljapur</option>
                            <option value="Tulsipur">Tulsipur</option>
                            <option value="Tumkur">Tumkur</option>
                            <option value="Tumsar">Tumsar</option>
                            <option value="Tundla">Tundla</option>
                            <option value="Tuni">Tuni</option>
                            <option value="Tura">Tura</option>
                            <option value="Uchgaon">Uchgaon</option>
                            <option value="Udaipur">Udaipur</option>
                            <option value="Udaipur">Udaipur</option>
                            <option value="Udaipurwati">Udaipurwati</option>
                            <option value="Udgir">Udgir</option>
                            <option value="Udhagamandalam">
                              Udhagamandalam
                            </option>
                            <option value="Udhampur">Udhampur</option>
                            <option value="Udumalaipettai">
                              Udumalaipettai
                            </option>
                            <option value="Udupi">Udupi</option>
                            <option value="Ujhani">Ujhani</option>
                            <option value="Ujjain">Ujjain</option>
                            <option value="Umarga">Umarga</option>
                            <option value="Umaria">Umaria</option>
                            <option value="Umarkhed">Umarkhed</option>
                            <option value="Umarkote">Umarkote</option>
                            <option value="Umbergaon">Umbergaon</option>
                            <option value="Umred">Umred</option>
                            <option value="Umreth">Umreth</option>
                            <option value="Una">Una</option>
                            <option value="Unjha">Unjha</option>
                            <option value="Unnamalaikadai">
                              Unnamalaikadai
                            </option>
                            <option value="Unnao">Unnao</option>
                            <option value="Upleta">Upleta</option>
                            <option value="Uran">Uran</option>
                            <option value="Uran islampur">Uran islampur</option>
                            <option value="Uravakonda">Uravakonda</option>
                            <option value="Urmar tanda">Urmar tanda</option>
                            <option value="Usilampatti">Usilampatti</option>
                            <option value="Uthamapalayam">Uthamapalayam</option>
                            <option value="Uthiramerur">Uthiramerur</option>
                            <option value="Utraula">Utraula</option>
                            <option value="Vadakara">Vadakara</option>
                            <option value="Vadakkuvalliyur">
                              Vadakkuvalliyur
                            </option>
                            <option value="Vadalur">Vadalur</option>
                            <option value="Vadgaon kasba">Vadgaon kasba</option>
                            <option value="Vadipatti">Vadipatti</option>
                            <option value="Vadnagar">Vadnagar</option>
                            <option value="Vadodara">Vadodara</option>
                            <option value="Vaijapur">Vaijapur</option>
                            <option value="Vaikom">Vaikom</option>
                            <option value="Valparai">Valparai</option>
                            <option value="Valsad">Valsad</option>
                            <option value="Vandavasi">Vandavasi</option>
                            <option value="Vaniyambadi">Vaniyambadi</option>
                            <option value="Vapi">Vapi</option>
                            <option value="Vapi">Vapi</option>
                            <option value="Varanasi">Varanasi</option>
                            <option value="Varkala">Varkala</option>
                            <option value="Vasai">Vasai</option>
                            <option value="Vedaranyam">Vedaranyam</option>
                            <option value="Vellakoil">Vellakoil</option>
                            <option value="Vellore">Vellore</option>
                            <option value="Venkatagiri">Venkatagiri</option>
                            <option value="Veraval">Veraval</option>
                            <option value="Vicarabad">Vicarabad</option>
                            <option value="Vidisha">Vidisha</option>
                            <option value="Vijainagar">Vijainagar</option>
                            <option value="Vijapur">Vijapur</option>
                            <option value="Vijayapura">Vijayapura</option>
                            <option value="Vijayawada">Vijayawada</option>
                            <option value="Vikramasingapuram">
                              Vikramasingapuram
                            </option>
                            <option value="Viluppuram">Viluppuram</option>
                            <option value="Vinukonda">Vinukonda</option>
                            <option value="Viramgam">Viramgam</option>
                            <option value="Virar">Virar</option>
                            <option value="Virudhachalam">Virudhachalam</option>
                            <option value="Virudhunagar">Virudhunagar</option>
                            <option value="Visakhapatnam">Visakhapatnam</option>
                            <option value="Visnagar">Visnagar</option>
                            <option value="Viswanatham">Viswanatham</option>
                            <option value="Vita">Vita</option>
                            <option value="Vizianagaram">Vizianagaram</option>
                            <option value="Vrindavan">Vrindavan</option>
                            <option value="Vyara">Vyara</option>
                            <option value="Wadgaon road">Wadgaon road</option>
                            <option value="Wadhwan">Wadhwan</option>
                            <option value="Wadi">Wadi</option>
                            <option value="Wai">Wai</option>
                            <option value="Wanaparthy">Wanaparthy</option>
                            <option value="Wani">Wani</option>
                            <option value="Wankaner">Wankaner</option>
                            <option value="Wara seoni">Wara seoni</option>
                            <option value="Warangal">Warangal</option>
                            <option value="Wardha">Wardha</option>
                            <option value="Warhapur">Warhapur</option>
                            <option value="Warisaliganj">Warisaliganj</option>
                            <option value="Warora">Warora</option>
                            <option value="Warud">Warud</option>
                            <option value="Washim">Washim</option>
                            <option value="Wokha">Wokha</option>
                            <option value="Yadgir">Yadgir</option>
                            <option value="Yamunanagar">Yamunanagar</option>
                            <option value="Yanam">Yanam</option>
                            <option value="Yavatmal">Yavatmal</option>
                            <option value="Yawal">Yawal</option>
                            <option value="Yellandu">Yellandu</option>
                            <option value="Yemmiganur">Yemmiganur</option>
                            <option value="Yerraguntla">Yerraguntla</option>
                            <option value="Yevla">Yevla</option>
                            <option value="Zahirabad">Zahirabad</option>
                            <option value="Zaidpur">Zaidpur</option>
                            <option value="Zamania">Zamania</option>
                            <option value="Zira">Zira</option>
                            <option value="Zirakpur">Zirakpur</option>
                            <option value="Zunheboto">Zunheboto</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <input
                            type="text"
                            name="landmark"
                            value={address.landmark}
                            onChange={handleChange}
                            class="form-control"
                            placeholder="Landmark"
                            required
                          />
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group">
                          <select
                            class="form-select"
                            name="state"
                            value={address.state}
                            onChange={handleChange}
                            placeholder="State"
                            aria-label="Default select example"
                            style={{
                              maxWidth: "510px",
                              marginLeft: "0px",
                              height: "49px",
                            }}
                          >
                            <option selected> Select State </option>
                            <option value="Andhra Pradesh">
                              Andhra Pradesh
                            </option>{" "}
                            <option value="Arunachal Pradesh">
                              Arunachal Pradesh
                            </option>{" "}
                            <option value="Assam">Assam</option>{" "}
                            <option value="Bihar">Bihar</option>{" "}
                            <option value="Chandigarh">Chandigarh</option>{" "}
                            <option value="Chhattisgarh">Chhattisgarh</option>{" "}
                            <option value="Delhi">Delhi</option>{" "}
                            <option value="Goa">Goa</option>{" "}
                            <option value="Gujarat">Gujarat</option>{" "}
                            <option value="Haryana">Haryana</option>{" "}
                            <option value="Himachal Pradesh">
                              Himachal Pradesh
                            </option>{" "}
                            <option value="Jharkhand">Jharkhand</option>{" "}
                            <option value="Karnataka">Karnataka</option>{" "}
                            <option value="Kerala">Kerala</option>{" "}
                            <option value="Madhya Pradesh">
                              Madhya Pradesh
                            </option>{" "}
                            <option value="Maharashtra">Maharashtra</option>{" "}
                            <option value="Manipur">Manipur</option>{" "}
                            <option value="Meghalaya">Meghalaya</option>{" "}
                            <option value="Mizoram">Mizoram</option>{" "}
                            <option value="Nagaland">Nagaland</option>{" "}
                            <option value="Orissa">Orissa</option>{" "}
                            <option value="Puducherry">Puducherry</option>{" "}
                            <option value="Punjab">Punjab</option>{" "}
                            <option value="Rajasthan">Rajasthan</option>{" "}
                            <option value="Sikkim">Sikkim</option>{" "}
                            <option value="TamilNadu">Tamil Nadu</option>{" "}
                            <option value="Telangana">Telangana</option>{" "}
                            <option value="Tripura">Tripura</option>{" "}
                            <option value="Uttar Pradesh">Uttar Pradesh</option>{" "}
                            <option value="Uttarakhand">Uttarakhand</option>{" "}
                            <option value="West Bengal">West Bengal</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <input
                            type="text"
                            name="zipcode"
                            value={address.zipcode}
                            maxLength={6}
                            onChange={handleChange}
                            className={`form-control  ${
                              pincodeError ? "is-invalid" : ""
                            }`}
                            placeholder="Pincode"
                            required
                          />
                        </div>
                        {pincodeError && (
                          <div
                            className="invalid-feedback"
                            style={{ color: "red" }}
                          >
                            {pincodeError}
                          </div>
                        )}
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <select
                            class="form-select"
                            value={address.addressType}
                            onChange={handleChange}
                            name="addressType"
                            aria-label="Default select example"
                            style={{
                              maxWidth: "510px",
                              marginLeft: "0px",
                              height: "49px",
                            }}
                          >
                            <option selected> Choose Address </option>
                            <option value="Home">Home </option>
                            <option value="Office">Office</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <select
                            class="form-select"
                            value={address.primary}
                            onChange={handleChange}
                            name="primary"
                            aria-label="Default select example"
                            style={{
                              maxWidth: "510px",
                              marginLeft: "0px",
                              height: "49px",
                            }}
                          >
                            <option selected>
                              {" "}
                              Is this Your Primary Address?
                            </option>
                            <option value="yes">Yes</option>
                            <option value="No">No</option>
                          </select>{" "}
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div
                          class="send-btn"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <button
                            type="submit"
                            class="default-btn border-radius-5"
                          >
                            Add Address
                            <span></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GetAddress />
    </div>
  );
}

export default AddressForm;
