import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Registers, globalLoderAction } from "../Redux/Action";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { message } from "antd";

function Register() {
  const [pass, setPass] = useState("");
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();
  const [phoneError, setPhoneError] = useState("");
  const [user, setUser] = useState({
    userName: "",
    email: "",
    password: "",
    phone: "",
    gender: "",
  });
  let navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (user.password !== pass) {
      message.warning({
        content: "Passwords do not match",
        style: {
          marginTop: "10vh",
        },
      });
    
      return;
    }
    dispatch(Registers(user)).then((res) => {
      message.success({
        content: res.data.data,
        style: {
          marginTop: "10vh",
        },
      });
      navigate("/login");
      setLoad(false);
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      if (!/^[0-9]{10}$/.test(e.target.value)) {
        setPhoneError("Please enter a valid 10-digit phone number");
      } else {
        setPhoneError("");
      }
    }

    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };
  console.log(user);
  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    const timer = setTimeout(() => {
      dispatch(globalLoderAction("hideLoader"));
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <div class="user-area pt-100 pb-70">
        <div class="container">
          <div
            class="user-all-form"
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <div class="contact-form">
              <div class="row align-items-center">
                <div class="container">
                  <h3 class="user-title"> Register </h3>
                  <form onSubmit={handleSubmit}>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <input
                            type="text"
                            name="userName"
                            value={user.userName}
                            onChange={handleChange}
                            class="form-control"
                            required
                            data-error="Username"
                            placeholder="Enter your name"
                          />
                        </div>
                      </div>
                      <div class="col-lg-12 ">
                        <div class="form-group">
                          <input
                            type="email"
                            name="email"
                            value={user.email}
                            required
                            onChange={handleChange}
                            class="form-control"
                            data-error="Please enter Email ID"
                            placeholder="Please Enter Email ID"
                          />
                        </div>
                      </div>
                      <div class="col-lg-12 ">
                        <div class="form-group">
                          <input
                            type="text"
                            name="phone"
                            value={user.phone}
                            onChange={handleChange}
                            className={`form-control  ${
                              phoneError ? "is-invalid" : ""
                            }`}
                            maxLength={10}
                            data-error="Please enter Phone Number"
                            placeholder="Please Enter Phone Number"
                            required
                          />
                        </div>
                        {phoneError && (
                          <div
                            className="invalid-feedback"
                            style={{ color: "red" }}
                          >
                            {phoneError}
                          </div>
                        )}
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <input
                            type="password"
                            id="form2Example2"
                            required
                            name="password"
                            value={user.password}
                            onChange={handleChange}
                            class="form-control"
                            placeholder="Enter Password"
                          />
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <input
                            type="password"
                            id="form2Example2"
                            required
                            name="confirmPassword"
                            value={pass}
                            onChange={(e) => setPass(e.target.value)}
                            class="form-control"
                            placeholder="Confirm Password"
                          />
                        </div>
                      </div>

                      <div class="col-lg-12 ">
                        <div class="form-group">
                          <select
                            value={user.gender}
                            onChange={handleChange}
                            name="gender"
                            class="form-select"
                            required
                          >
                            <option value="Choose Your Gender">
                              Choose Your Gender
                            </option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                      <button type="submit" class="default-btn border-radius-5">
                        Register Now
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Register;
