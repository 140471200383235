import React, { useState, useEffect } from "react";
import { globalLoderAction } from "../Redux/Action";
import { FaLocationDot } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { PRIMARY_COLOR } from "../contants/constant";

function Contact() {
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    const timer = setTimeout(() => {
      dispatch(globalLoderAction("hideLoader"));
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <div class="inner-banner inner-banner-bg12">
        <div class="container">
          <div class="inner-title">
            <h3 style={{ color: PRIMARY_COLOR }}>Contact Us</h3>
            <ul>
              <li>
                <a href="/" style={{ color: "#f9c113" }}>
                  Home
                </a>
              </li>
              <li style={{ color: "#000" }}>Contact Us</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="contact-info-area pt-100 pb-70">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-3 col-12">
              <div class="contact-info-card">
                <i class="bx bx-phone"></i>
                <h3>Phone</h3>
                <p>
                  <a href="tel:+91595 55224">+91 63790 60691</a>
                </p>
              </div>
            </div>

            <div class="col-lg-4 col-12">
              <div class="contact-info-card">
                <i class="bx bx-mail-send"></i>
                <h3>Email </h3>
                <p>
                  <a href="">
                    <span
                      class="__cf_email__"
                      data-cfemail="48202d242427082a293a3029662b2725"
                    >
                      beautytalescosmetics@gmail.com
                    </span>
                  </a>
                </p>
              </div>
            </div>

            <div class="col-lg-5  col-12 ">
              <div class="contact-info-card">
                <i class="bx bx-current-location"></i>
                <h3>Address </h3>
                <p>
                  {" "}
                  9, Thiru. Vee. Ka. Nagar, 5th street, Dharapuram Main Rd, Perichipalayam,
                </p>
                <p>Tiruppur, Tamil Nadu 641604</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="contact-widget-area pb-70">
        <div class="container">
          <div class="row">
            {/* <div class="col-lg-6">
                        <div class="contact-form mr-20">
                            <h3>Contact Us</h3>
                            <form id="contactForm">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <input type="text" name="name" id="name" class="form-control" required data-error="Please Enter Your Name" placeholder="Name"/>
                                            <div class="help-block with-errors"></div>
                                        </div>
                                    </div>
    
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <input type="email" name="email" id="email" class="form-control" required data-error="Please Enter Your Email" placeholder="Email"/>
                                            <div class="help-block with-errors"></div>
                                        </div>
                                    </div>
    
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <input type="text" name="phone_number" id="phone_number" required data-error="Please Enter Your number" class="form-control" placeholder="Phone Number"/>
                                            <div class="help-block with-errors"></div>
                                        </div>
                                    </div>
    
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" required data-error="Please Enter Your Subject" placeholder="Your Subject"/>
                                            <div class="help-block with-errors"></div>
                                        </div>
                                    </div>
    
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <textarea name="message" class="form-control" id="message" cols="30" rows="7" required data-error="Write your message" placeholder="Your Message"></textarea>
                                            <div class="help-block with-errors"></div>
                                        </div>
                                    </div>
    
                                    <div class="col-lg-12">
                                        <div class="agree-label">
                                            <input type="checkbox" id="chb1"/>
                                            <label for="chb1">
                                                Accept <a href="terms-condition.html">Terms & Conditions</a> And <a href="privacy-policy.html">Privacy Policy.</a>
                                            </label>
                                        </div>
                                    </div>
    
                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" class="default-btn border-radius-5">
                                            Submit Now 
                                        </button>
                                        <div id="msgSubmit" class="h3 text-center hidden"></div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div> */}

            <div class="col-lg-12">
              <div class="col-lg-12 col-12">
                <div class="contact-info-card">
                  <i class="bx bx-time"></i>
                  <h3 className="pb-5">Business Hours</h3>
                  <p>
                    <span style={{ fontWeight: "800" }}>Monday to Thursday </span>
                    : 9:00 AM - 6:00 PM
                  </p>
                  <br />
                  <p>
                    <span style={{ fontWeight: "800" }}>Saturday & Sunday </span>: 9:00 AM - 6:00 PM
                  </p>
                  <br />
                  <p>
                    <span style={{ fontWeight: "800" }}>Friday </span>: Closed
                  </p>
                </div>
              </div>
              {/* <div class="contact-map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d977.9173633610678!2d77.7532459695964!3d11.358836599301737!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba96726adfd9b37%3A0xa7efd6fcfb075315!2sAadhi%20Marudha%20Shop!5e0!3m2!1sen!2sin!4v1699340223002!5m2!1sen!2sin"
                  width="600"
                  height="450"
                  style={{ border: "0" }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>{" "}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Contact;
