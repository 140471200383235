import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { addAddress, getAddress, globalLoderAction } from "../Redux/Action";
import { useSelector, useDispatch } from "react-redux";
import GetAddress from "./GetAddress";
import Swal from "sweetalert2";
import { message } from "antd";
import "./AddressForm.css";
function BillingAddress() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  const [pincodeError, setPincodeError] = useState("");
  const [address, setAddress] = useState({
    state: "",
    city: "",
    street: "",
    landmark: "",
    zipcode: "",
    primary: "",
    district: "",
    addressType: "",
  });
  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    const timer = setTimeout(() => {
      dispatch(globalLoderAction("hideLoader"));
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  const handleChange = (e) => {
    if (e.target.name === "zipcode") {
      if (!/^[0-9]{10}$/.test(e.target.value)) {
        setPincodeError("Please enter a valid Pincode");
      } else {
        setPincodeError("");
      }
    }
    setAddress({ ...address, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(addAddress(address))
      .then((res) => {
        console.log(res.data.data);
        message.success({
          content: res.data.data,
          style: {
            marginTop: "10vh",
          },
        });
        if (res.data.data === "Created Successfully") {
          navigate("/checkout");
          dispatch(getAddress());
        }
      })
      .catch((err) => {
        //
      });
  };
  useEffect(() => {
    window.scrollTo(1000, 0);
  }, []);
  return (
    <div>
      <div class="inner-banner inner-banner-bg10">
        <div class="container">
          <div class="inner-title">
            <h3 style={{ color: "#05af6a" }}>Billing Address</h3>
            <ul>
              <li>
                <a href="/" style={{ color: "#f9c113" }}>
                  Checkout
                </a>
              </li>
              <li style={{ color: "#000" }}>Billing Address</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="user-area pt-100 pb-70">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-12">
              <div class="user-all-form">
                <div class="contact-form">
                  <h3 class="user-title">Add Address</h3>
                  <form onSubmit={handleSubmit}>
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <input
                            type="text"
                            name="street"
                            value={address.street}
                            onChange={handleChange}
                            class="form-control"
                            placeholder="Street"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <input
                            type="text"
                            name="city"
                            value={address.city}
                            onChange={handleChange}
                            class="form-control"
                            placeholder="City"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <input
                            type="text"
                            name="district"
                            value={address.district}
                            onChange={handleChange}
                            class="form-control"
                            placeholder="District"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <input
                            type="text"
                            name="landmark"
                            value={address.landmark}
                            onChange={handleChange}
                            class="form-control"
                            placeholder="Landmark"
                            required
                          />
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group">
                          <input
                            type="text"
                            name="state"
                            value={address.state}
                            onChange={handleChange}
                            class="form-control"
                            placeholder="State"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <input
                            type="text"
                            name="zipcode"
                            value={address.zipcode}
                            maxLength={6}
                            onChange={handleChange}
                            className={`form-control  ${
                              pincodeError ? "is-invalid" : ""
                            }`}
                            placeholder="Pincode"
                            required
                          />
                        </div>
                        {pincodeError && (
                          <div
                            className="invalid-feedback"
                            style={{ color: "red" }}
                          >
                            {pincodeError}
                          </div>
                        )}
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <select
                            class="form-select"
                            value={address.addressType}
                            onChange={handleChange}
                            name="addressType"
                            aria-label="Default select example"
                            style={{
                              maxWidth: "510px",
                              marginLeft: "0px",
                              height: "49px",
                            }}
                          >
                            <option selected> Choose Address </option>
                            <option value="Home">Home </option>
                            <option value="Office">Office</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <select
                            class="form-select"
                            value={address.primary}
                            onChange={handleChange}
                            name="primary"
                            aria-label="Default select example"
                            style={{
                              maxWidth: "510px",
                              marginLeft: "0px",
                              height: "49px",
                            }}
                          >
                            <option selected>
                              {" "}
                              Is this Your Primary Address?
                            </option>
                            <option value="yes">Yes</option>
                            <option value="No">No</option>
                          </select>{" "}
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div
                          class="send-btn"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <button
                            type="submit"
                            class="default-btn border-radius-5"
                          >
                            Add Address
                            <span></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GetAddress />
    </div>
  );
}

export default BillingAddress;
