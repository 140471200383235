import React, { useEffect, useState } from "react";
import { myreviews } from "../Redux/Action";
import { useDispatch, useSelector } from "react-redux";
import { deletereviews, globalLoderAction } from "../Redux/Action";
import reviews from "../assets/image/reviews found.svg";
import { Link } from "react-router-dom";
import ReviewModal from "./ReviewModal";
import Swal from "sweetalert2";
import { message } from "antd";

function ListReviews() {
  const [show, setShow] = useState(true);
  const [load, setLoad] = useState(true);
  const [render, setRender] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    dispatch(myreviews())
      .then(() => setLoad(false))
      .catch(() => {
        console.log("Waiting For Network");
      })
      .finally(() => {
        dispatch(globalLoderAction("hideLoader"));
      });
  }, [dispatch]);

  const myreview = useSelector((state) => state.myreviews.payload);

  const handlereview = (G) => {
    dispatch(
      deletereviews({
        action: "delete",
        id: G.id,
        rating: G.rating,
        review: G.review,
      }).then((res) => {
        message.success({
          content: res.data.data,
          style: {
            marginTop: "10vh",
          },
        });
      })
    );
    setRender(!render);
  };

  return (
    <div>
      <div className="view-more mt-5">
        <a href="/shop" className="default-btn border-radius-5">
Add Your Review
        </a>
      </div>
      <section className="cart-wraps-area pt-10 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="cart-wraps">
                <div className="cart-table table-responsive">
                  <table className="table table-bordered" style={{ borderRadius: "20px" }}>
                    <thead>
                      {Array.isArray(myreview) && myreview.length > 0 ? (
                        <tr>
                          <th scope="col">Product Image</th>
                          <th scope="col">Product Name</th>
                          <th scope="col">Review</th>
                          <th scope="col">Rating</th>
                          <th scope="col">Action</th>
                        </tr>
                      ) : (
                        <tr>
                          <td colSpan={5} style={{ textAlign: "center" }}>
                            <h3 className="pt-5 pb-5">
                              <center>
                                <img
                                  src={reviews}
                                  className="mt-5 mb-5 pb-5 pt-5"
                                  style={{ width: "25%", objectFit: "cover" }}
                                />
                                <h3>No Reviews Found</h3>
                              </center>
                            </h3>
                          </td>
                        </tr>
                      )}
                    </thead>
                    <tbody>
                      {Array.isArray(myreview) && myreview.length !== 0 ? (
                        myreview.map((G, i) => (
                          <tr key={i}>
                            <td className="product-thumbnail">
                              <Link
                                to={`/ProductDetails/${G.productId}`}
                                style={{ textDecoration: "none" }}
                              >
                                <span>
                                  <img
                                    style={{ width: "100px" }}
                                    src={`${process.env.REACT_APP_URL}${G.variantImage}`}
                                  />
                                </span>
                              </Link>
                            </td>
                            <td className="product-name">
                              <Link
                                to={`/ProductDetails/${G.productId}`}
                                style={{
                                  textTransform: "capitalize",
                                  textDecoration: "none",
                                  color: "black",
                                }}
                              >
                                {G.productName}
                              </Link>
                            </td>
                            <td className="product-price">
                              <Link
                                to={`/ProductDetails/${G.productId}`}
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                              >
                                <span style={{ textTransform: "capitalize" }}>
                                  {G.review}
                                </span>
                              </Link>
                            </td>
                            <td className="product-name">{G.rating}</td>
                            <td className="product-subtotal">
                              <span
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                              >
                                <a
                                  style={{
                                    cursor: "pointer",
                                    paddingLeft: "10px",
                                  }}
                                  onClick={() => {
                                    if (show !== i) setShow(i);
                                  }}
                                >
                                  <i
                                    style={{
                                      fontSize: "23px",
                                      backgroundColor: "#0000001c",
                                      borderRadius: "0",
                                      padding: "10px",
                                    }}
                                    className="bx bx-edit-alt"
                                  ></i>
                                </a>
                              </span>
                              <span onClick={() => handlereview(G)}>
                                <a
                                  style={{
                                    cursor: "pointer",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  <i
                                    style={{
                                      fontSize: "23px",
                                      backgroundColor: "#0000001c",
                                      borderRadius: "0",
                                      padding: "10px",
                                    }}
                                    className="bx bx-trash"
                                  ></i>
                                </a>
                              </span>
                            </td>
                          </tr>
                        ))
                      ) : (
                     false
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Review
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {show || show === 0 ? (
                <ReviewModal myreview={myreview?.[show]} />
              ) : (
                false
              )}
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListReviews;
