import React, { useState, useEffect } from "react";
import { globalLoderAction } from "../Redux/Action";
import { BsCaretRightFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import about from "../assets/image/about.jpeg";
import about1 from "../assets/image/about1.jpeg";
import about2 from "../assets/image/about2.jpeg";
import about3 from "../assets/image/about1.jpg";
import { PRIMARY_COLOR } from "../contants/constant";

function About() {
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    const timer = setTimeout(() => {
      dispatch(globalLoderAction("hideLoader"));
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <div class="inner-banner inner-banner-bg12">
        <div class="container">
          <div class="inner-title">
            <h3 style={{ color: PRIMARY_COLOR }}>About us</h3>
            <ul>
              <li>
                <a style={{ color: "#f9c113" }} href="/">
                  Home
                </a>
              </li>
              <li style={{ color: "#000" }}>About us</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="cosmetic-area pt-100 pb-70">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="cosmetic-content">
                <div class="section-title">
                  <div class="section-subtitle">
                    <img
                      src="assets/images/section-icon.png"
                      alt="Section Images"
                    />
                    <span>Our Mission</span>
                  </div>

                  <p style={{ textAlign: "justify" }}>
                    <BsCaretRightFill style={{ color: "#F290BB" }} />
                    At beautytales, our mission is to provide high-quality,
                    organic beauty products that nourish and enhance the natural
                    beauty of our customers.
                    <p style={{ textAlign: "justify" }}></p>
                    <BsCaretRightFill style={{ color: "#F290BB" }} />
                    {""} We are committed to sustainability, ethical sourcing,
                    and promoting overall well-being through the power of
                    nature.
                  </p>
                  <p style={{ textAlign: "justify" }}>
                    {" "}
                    <BsCaretRightFill style={{ color: "#F290BB" }} />
                    {""} Our goal is to empower individuals to make healthier,
                    more environmentally conscious choices in their beauty
                    routines.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="cosmetic-img">
                <img src={about2} alt="Cosmetic" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="about-area-three pt-100 pb-70">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="about-img3">
                <img src={about} alt="About" />
                <div class="about-sub-img">
                  <img src={about1} alt="About" />
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="about-content-two">
                <div class="section-title">
                  <div class="section-subtitle">
                    <img
                      src="assets/images/section-icon.png"
                      alt="Section Images"
                    />
                    <span>About Us</span>
                  </div>
                  <h2>Why Beauty Tales?</h2>
                </div>
                <div class="row">
                  <div class="col-lg-12 col-12">
                    <ul class="about-list">
                      <li>
                        <i class="bx bx-check"></i>
                        <h3>Price</h3>
                        <p style={{ textAlign: "justify" }}>
                          Our price points empower our consumers to explore
                          their preferences, desires, and needs. There's
                          something for everyone within the Beauty Tales
                          portfolio, ensuring accessibility without compromising
                          quality.
                        </p>
                      </li>
                      <li>
                        <i class="bx bx-check"></i>
                        <h3>Innovation</h3>
                        <p style={{ textAlign: "justify" }}>
                          Innovation drives us forward, allowing us to cater to
                          your every need. You dream it, and we deliver it. Our
                          R&D and product development teams work tirelessly,
                          always listening to your feedback and inputs to create
                          exceptional products.
                        </p>
                      </li>{" "}
                      <li>
                        <i class="bx bx-check"></i>
                        <h3>Quality</h3>
                        <p style={{ textAlign: "justify" }}>
                          Our products are crafted with high-quality organic
                          ingredients, luxurious textures, innovative formulas,
                          and practical, eco-friendly packaging. Each item is
                          designed to deliver the best of nature to our valued
                          customers.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="cosmetic-area pt-100 pb-70">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="cosmetic-content">
                <div class="section-title">
                  <div class="section-subtitle">
                    <img
                      src="assets/images/section-icon.png"
                      alt="Section Images"
                    />
                    <span>Our Vision</span>
                  </div>
                  <p style={{ textAlign: "justify" }}>
                    <BsCaretRightFill style={{ color: "#F290BB" }} />
                    Our vision is to become a leading brand in the organic
                    beauty industry, recognized for our integrity, innovation,
                    and dedication to holistic wellness.
                  </p>
                  <p style={{ textAlign: "justify" }}>
                    <BsCaretRightFill style={{ color: "#F290BB" }} />
                    {""} We aim to inspire a global movement towards natural
                    beauty and sustainability, creating a world where everyone
                    can enjoy the benefits of pure, organic products without
                    compromising on quality or the health of our planet.
                  </p>
                </div>
              </div>
            </div>{" "}
            <div class="col-lg-6">
              <div class="cosmetic-img">
                <img src={about3} alt="Cosmetic" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div class="cosmetic-area pt-100 pb-70">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="cosmetic-img">
                <img src={about3} alt="Cosmetic" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="cosmetic-content">
                <div class="section-title">
                  <div class="section-subtitle">
                    <img
                      src="assets/images/section-icon.png"
                      alt="Section Images"
                    />
                    <span>We are a firm Comb of Bees</span>
                  </div>
                  <p style={{textAlign:"justify"}}>
                    <BsCaretRightFill style={{ color: "#F290BB" }} /> {""}
                    United with a vision that resonates across our hearts and
                    minds- we envision to solve challenges prevailing in
                    bringing the natural sweetener "honey" to common people's
                    dining table.
                  </p>{" "}
                  <p style={{textAlign:"justify"}}>
                    <BsCaretRightFill style={{ color: "#F290BB" }} /> Our
                    initial search, though had begun with organic farming, we
                    landed at a point where we were hardly hit by the
                    understanding, of the role played by Honey Bees in
                    agriculture and the value Honey naturally provided.
                  </p>{" "}
                  <p style={{textAlign:"justify"}}>
                    <BsCaretRightFill style={{ color: "#F290BB" }} /> {""}
                    And this led to deeper experiments and then varieties of
                    Honey from Beauty Tales.
                  </p>
                  <p style={{textAlign:"justify"}}>
                    <BsCaretRightFill style={{ color: "#F290BB" }} /> Closely
                    Knit by Intentions, Largely Diverse by Experiments!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div class="feature-area pt-100 pb-70">
        <div class="container">
          {/* <div class="section-title text-center mb-45">
                    <div class="section-subtitle">
                        <img src="assets/images/section-icon.png" alt="Section Images" />
                        <span>Feature</span>
                    </div>
                    <h2>Our Services Is Much Better</h2>
                </div> */}

          <div class="row justify-content-center">
            <div class="col-lg-12 col-12">
              <h3>
                <i>
                  {" "}
                  “To keep the body in good health is a duty… otherwise we shall
                  not be able to keep our mind strong and clear.”
                </i>{" "}
                - <b>Buddha</b>
              </h3>
            </div>
          </div>
        </div>
      </div>

      {/* <div class="testimonials-area testimonials-bg2 ptb-100">
        <div class="container">
          <div class="testimonials-slider owl-carousel owl-theme">
            <div class="testimonials-item">
              <div class="section-title text-center">
                <div class="section-subtitle">
                  <img
                    src="assets/images/section-icon.png"
                    alt="Section Images"
                  />
                  <span>Testimonials</span>
                </div>
                <h2>Our Client Feedback</h2>
              </div>
              <i class="bx bxs-quote-right"></i>
              <p style={{textAlign:"justify"}}>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humourrandomised words which don't look even slightly
                believable. If you are going to use a passage of LoremIpsum, you
                needbe sure therfirst true generator on the Internet. It uses a
                dictionary of over
              </p>
              <h3>Elizabeth Diana</h3>
              <span>SEO - Founder</span>
            </div>

            <div class="testimonials-item">
              <div class="section-title text-center">
                <div class="section-subtitle">
                  <img
                    src="assets/images/section-icon.png"
                    alt="Section Images"
                  />
                  <span>Testimonials</span>
                </div>
                <h2>Our Client Feedback</h2>
              </div>
              <i class="bx bxs-quote-right"></i>
              <p style={{textAlign:"justify"}}>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humourrandomised words which don't look even slightly
                believable. If you are going to use a passage of LoremIpsum, you
                needbe sure therfirst true generator on the Internet. It uses a
                dictionary of over
              </p>
              <h3>Aruna Malis</h3>
              <span>Makeup Artists</span>
            </div>

            <div class="testimonials-item">
              <div class="section-title text-center">
                <div class="section-subtitle">
                  <img
                    src="assets/images/section-icon.png"
                    alt="Section Images"
                  />
                  <span>Testimonials</span>
                </div>
                <h2>Our Client Feedback</h2>
              </div>
              <i class="bx bxs-quote-right"></i>
              <p style={{textAlign:"justify"}}>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humourrandomised words which don't look even slightly
                believable. If you are going to use a passage of LoremIpsum, you
                needbe sure therfirst true generator on the Internet. It uses a
                dictionary of over
              </p>
              <h3>Christopher Evan</h3>
              <span>Hairdresser</span>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div class="newsletter-area-widget section-bg ptb-100">
        <div class="container">
          <div class="newsletter-area">
            <div class="section-title text-center mb-45">
              <h2>Signup to Our Newsletter</h2>
              <p style={{textAlign:"justify"}}>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered
              </p>
            </div>
            <form class="newsletter-form" data-toggle="validator" method="POST">
              <input
                type="email"
                class="form-control"
                placeholder="Enter Your Email"
                name="EMAIL"
                required
                autocomplete="off"
              />
              <button class="subscribe-btn" type="submit">
                Subscribe
              </button>
              <div id="validator-newsletter" class="form-result"></div>
            </form>
          </div>
        </div>

        <div class="newsletter-shape">
          <div class="shape1">
            <img src="assets/images/shape/shape2.png" alt="Images" />
          </div>

          <div class="shape2">
            <img src="assets/images/shape/shape1.png" alt="Images" />
          </div>
        </div> 
      </div>*/}
    </>
  );
}
export default About;
