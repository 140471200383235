import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { globalLoderAction } from "../../Redux/Action";
import blog1 from "../../assets/image/blog1.jpg";
import blog2 from "../../assets/image/blog2.jpg";
import blog3 from "../../assets/image/blog4.jpg";
import { PRIMARY_COLOR } from "../../contants/constant";

function Blogs() {
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    const timer = setTimeout(() => {
      dispatch(globalLoderAction("hideLoader"));
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <div class="inner-banner inner-banner-bg12">
        <div class="container">
          <div class="inner-title">
            <h3 style={{ color: PRIMARY_COLOR }}>Blogs</h3>
            <ul>
              <li>
                <a href="/" style={{ color: "#f9c113" }}>
                  Home
                </a>
              </li>
              <li style={{ color: "#000" }}>Blogs</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="blog-area pt-100 pb-70">
        <div class="container">
          <div class="section-title text-center mb-45">
            <div class="section-subtitle">
              <img src="assets/images/section-icon.png" alt="Section Images" />
              <span>Latest News</span>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
              <div class="blog-item">
                <a href="/blogs_one">
                  <img src={blog2} alt="Blog" />
                </a>
                <div class="content">
                  <h4 style={{ color: "black" }}>
                    Embrace Natural Freshness with Beauty Tales's Rose
                    Deo Stick
                  </h4>

                  <p style={{ textAlign: "justify" }}>
                    We all love to smell good, but many perfumes and deodorants
                    are full of harmful chemicals. Beauty Tales's Rose
                    Deo Stick offers a natural alternative, made with pure,
                    organic ingredients and the essence of Bulgarian roses. It’s
                    chemical-free, gentle on your skin, and keeps you smelling
                    fresh all day. Choose natural, stay healthy, and smell
                    amazing!
                  </p>
                  <a href="/blogs_one" class="read-btn">
                    Read More
                  </a>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="blog-item">
                <a href="/blogs_two">
                  <img src={blog1} alt="Blog" />
                </a>
                <div class="content">
                  <h4>Choose Wellness with Beauty Tales</h4>

                  <p style={{ textAlign: "justify" }}>
                    Discover the power of nutritious choices at B. From promoting active living and glowing skin to
                    embracing Ayurvedic wisdom, our focus on Satvik foods
                    ensures vitality and well-being. Choose wellness today and
                    nourish your body with the goodness of nature.
                  </p>
                  <a href="/blogs_two" class="read-btn">
                    Read More
                  </a>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="blog-item">
                <a href="/blogs_three">
                  <img src={blog3} alt="Blog" />
                </a>
                <div class="content">
                  <h4>
                    SAY GOODBYE TO DARK CIRCLES: HERE’S HOW YOU CAN REDUCE THEM
                  </h4>

                  <p style={{ textAlign: "justify" }}>
                    Pandas are adorable, right? However, not when you are
                    beginning to look like one. Nowadays, it's difficult to meet
                    anyone who isn't struggling to achieve healthy-looking skin.
                    Dark circles, often aggravated by poor sleeping schedules,
                    have become a common issue. They seem to have zero plans of
                    leaving us.
                  </p>
                  <a href="/blogs_three" class="read-btn">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Blogs;
